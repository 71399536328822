import http from "@/config/http";

/*************** <CONTACTS> *********************/
export const getContacts = async () => http.get(`v1/contacts`);

export const createContact = async (data) => http.post(`v1/contacts`, data);

export const updateContact = async (id, data) => http.put(`v1/contacts/${id}`, data);

export const deleteContact = async (id) => http.delete(`v1/contacts/${id}`);

export const importContacts = async (contacts) => http.post(`v1/contacts/import`, contacts);

/*************** <CONTACTS/> *********************/