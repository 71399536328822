import http from "@/config/http";

/*************** <CONTENT> *********************/
export const getContents = async () => http.get(`v1/contents`);

export const getContentsByRole = async (role) => http.get(`v1/contents?role=${role}`);

export const createContent = async (data) => http.post(`v1/contents`, data);

export const updateContent = async (id, data) => http.put(`v1/contents/${id}`, data);

export const deleteContent = async (id) => http.delete(`v1/contents/${id}`);
/*************** <CONTENT/> *********************/
