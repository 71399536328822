import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { filesAPI, activitiesAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import { DocumentVisualizer, BtnDocumentVisualizer, BtnReturnFolder, BtnDownloadFile } from "@/styles/Template";
import Loading from "@/components/Loading";

const Preview = () => {
  const user = useContext(UserContext);
  let {fileID} = useParams();
  const [embedUrl, setEmbedURL] = useState("");
  const [fileData, setFileData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [parent,setParent] = useState([]);
  
  const handleDownload = async id => {
    try {
      const {data: {response: fileDownloadURL}} = await filesAPI.getFileDownloadLink(id);

      const info = {
        description: "You downloaded a file: " + fileData.name,
        action: "download",
        fileName: fileData.name,
        link: "/dashboard/files/preview/" + id,
        user: user.id,
        folderId: fileData.parent.id
      };

      await activitiesAPI.createActivity(info);

      let element = document.createElement("a");
      element.setAttribute("href", fileDownloadURL);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getEmbedUrl = async () => {

      try {
        const {data: {response: file}} = await filesAPI.getFileEmbedUrl(fileID);
        const {data: {response: fileData}} = await filesAPI.getFileData(fileID);
        
        setEmbedURL(file);
        setFileData(fileData);
        setParent(fileData.parent);
        setLoading(false);
        
        const info = {
          description: "You previewed a file: " + fileData.name,
          action: "preview",
          fileName: fileData.name,
          link: "/dashboard/files/preview/" + fileID,
          user: user.id,
          folderId: fileData.parent.id
        };
  
        await activitiesAPI.createActivity(info);
      } catch (error) {
        console.log(error);
      }
    };
    
    getEmbedUrl();
  },[user,fileID]);

  return (
    <div className="row">
      {isLoading ? (
        <Loading />
      ):
        <DocumentVisualizer>
          <BtnDocumentVisualizer>
            <BtnReturnFolder><Link to={`/dashboard/folders/${parent.id}`}><span className="glyphicon glyphicon-chevron-left" aria-hidden="true"></span> Back </Link></BtnReturnFolder>
            <BtnDownloadFile onClick={() => handleDownload(fileID)}><span className="glyphicon glyphicon-download-alt" aria-hidden="true"></span> Download </BtnDownloadFile>
          </BtnDocumentVisualizer>
          <iframe title="Document" src={embedUrl}></iframe>
        </DocumentVisualizer>
      }
    </div>
  );
}

export default Preview;