import { useContext, useEffect, useState } from "react";
import { Row, Col, Button, Table, /* Pagination */ } from "react-bootstrap";
import { toast } from "react-toastify";
import { MsgNotification } from "@/config/toast";
import { rolesAPI, logsAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import { NoDataMessage } from "@/layouts/Admin";
import Search from "@Admin/components/Search";
import AddRole from "./addRole";
import EditRole from "./editRole";

const ListRoles = () => {

  const user = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  let [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editData, setEditData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const {data: {response: roles} } = await rolesAPI.getRoles();
      setData(roles);
    }

    getData();
  }, []);

  const handleShowModal = () => setShowModal(true);

  const handleShowEditModal = e => {
    const editData = data.filter(d => d.id === e.currentTarget.dataset.id);
    setShowEditModal(true);
    setEditData(editData);
  };

  const handleCloseModal = info => {
    if(info !== undefined){
      setShowModal(false);
      setData([...data, info]);
    }else{
      setShowModal(false);
    }
  }

  const handleCloseEditModal = info => {
    if (info !== undefined) {
      const info2 = data.map(d => d.id === info.id ? info : d);

      setShowEditModal(false);
      setData([...info2]);
    } else {
      setShowEditModal(false);
    }
  };

  const removeRole = async id => {
    // Get role info
    const role = data.find(d => d.id === id);
    const action_type = 'DELETE';
    const description = action_type + ' ROLE: ' + role.name;
    const module = 'ROLES';

    const logInfo = {
      action_type,
      description,
      module,
      user: user.id
    };

    try {
      await rolesAPI.deleteRole(id);

      // Create log
      await logsAPI.createLog(logInfo);
      toast(<MsgNotification msg="Role has been deleted!" />);
      const info = data.filter(d => d.id !== id);
      setData(info);
    } catch (error) {
      toast.error(error);
    }
  };

  data = isSearching? filteredData : data;

  return (
    <>
      <Col lg={12}>
        <Row>
          <Col lg={6}>
            <Button variant="success" style={{ marginTop: "23px" }} onClick={handleShowModal}>
              <i className="pe-7s-plus"></i> Add new role
            </Button>
          </Col>
          <Col lg={6} className="pull-right">
            <Search data={data} text="Type a role name..." attribute="name" 
              filteredData={(filteredData, isSearching) => {
                setFilteredData(filteredData);
                setIsSearching(isSearching);
              }}/>
          </Col>
        </Row>
        {data.length > 0 ? 
          <Table striped bordered responsive hover style={{ marginTop: "23px" }}>
            <thead>
              <tr>
                <th>Role name</th>
                <th>NodeBB group slug</th>
                <th>Hierarchy</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map(d => (
                <tr key={d.id}>
                  <td>{d.name}</td>
                  <td>{d.group_slug}</td>
                  <td>{d.roleOrder}</td>
                  <td>
                    <Button variant="warning" onClick={handleShowEditModal} data-id={d.id}>
                      <i className="pe-7s-pen"></i> Edit
                    </Button>
                    <Button variant="danger" onClick={() => {
                        const r = window.confirm(
                          "Are you sure you want to delete this item?"
                        );
                        if (r) removeRole(d.id);
                      }}
                      data-id={d.id}
                    >
                      <i className="pe-7s-trash"></i> Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          : isSearching ? (
            <NoDataMessage>
              No results found.
              <br />
              Try adjusting your search to find what you're looking
              for.
            </NoDataMessage>
          ) : (
            <NoDataMessage>
              It looks like you don't have any information yet.{" "}
              <br />
              Once you add information, this page will show it.{" "}
            </NoDataMessage>
          )}

        {/*<div className="text-center">
            <Pagination>
            <Pagination.First />
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Ellipsis />

            <Pagination.Item>{10}</Pagination.Item>
            <Pagination.Item>{11}</Pagination.Item>
            <Pagination.Item active>{12}</Pagination.Item>
            <Pagination.Item>{13}</Pagination.Item>
            <Pagination.Item disabled>{14}</Pagination.Item>

            <Pagination.Ellipsis />
            <Pagination.Item>{20}</Pagination.Item>
            <Pagination.Next />
            <Pagination.Last />
            </Pagination>
        </div>*/}
      </Col>
      
      <AddRole
        data={data}
        showModal={showModal}
        closeModal={handleCloseModal}
      />

      {showEditModal && (
        <EditRole
          data={editData[0]}
          allData={data}
          showModal={showEditModal}
          closeModal={handleCloseEditModal}
        />
      )}
    </>
  )
}

export default ListRoles;