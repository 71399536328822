import { Container, Row, Col } from "react-bootstrap";
import Card from "@Admin/components/Card";
import ListNewsletter from './listNewsletter';

const NewsLetters = () => {
  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card title="Newsletters" ctTableFullWidth ctTableResponsive >
              <ListNewsletter />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NewsLetters;