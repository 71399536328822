import { Card } from "react-bootstrap";

const CardWrapper = (props) =>
  <Card>
    <Card.Header>{props.title}</Card.Header>
      <Card.Body>
        {props.children}
      </Card.Body>
  </Card>

export default CardWrapper;