import { useContext, useState } from "react";
import { Row, Col, Form, Button, Modal, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import { MsgNotification } from "@/config/toast";
import { rolesAPI, logsAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import { validString } from "@/helpers/MainFunctions";
import { SpinnerLoading } from "@/styles/Template";

const AddRole = (props) => {

  const user = useContext(UserContext);
  const [role, setRole] = useState("");
  const [hierarchy, setHierarchy] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasSentData, setHasSentData] = useState(false);

  const onChangeRole = e => {
    const role = e.target.value;

    //Validating role name.
    const response = validRole(role);
    
    setRole(role);
    setError(response.error);
    setErrorMessage(response.errorMessage);
  };

  const onBlurRole = e => {
    const role = e.target.value.trim();

    //Validating role name.
    const response = validRole(role);
    setRole(role);
    setError(response.error);
    setErrorMessage(response.errorMessage);
  };

  const onChangeHierarchy = e => {
    const hierarchy = parseInt(e.target.value);
    setHierarchy(hierarchy);
  };

  //Search If a role exists.
  const searchRole = role => props.data.filter(d => d.name === role).length > 0;

  const validRole = role => {
    const isDuplicated = searchRole(role);
    const isValidRole = validString(role);

    if (isDuplicated)
      return { error: isDuplicated, errorMessage: "Role name is duplicated." };

    if (isValidRole)
      return { error: isValidRole, errorMessage: "Role name is invalid." };

    return { error: false, errorMessage: "" };
  };

  //Send role information to API.
  const onSubmitRole = async () => {
    try {
      setHasSentData(true);
      const info = {
        name: role,
        roleOrder: hierarchy
      };
  
      const action_type = 'CREATE';
      const description = action_type + ' ROLE: ' + info.name;
      const module = 'ROLES';
  
      const logInfo = {
          action_type,
          description,
          module,
          user: user.id
      };
  
      const {data: {response: createdRole} } = await rolesAPI.createRole(info);

      // Create log
      await logsAPI.createLog(logInfo);

      toast(<MsgNotification msg="Role has been created!" />);

      resetState();
      setHasSentData(false);
      props.closeModal(createdRole);
    } catch (error) {
      setHasSentData(false);
      setError(true);
      setErrorMessage('Sorry! An error ocurred while processing your request.');
    }
  };

  const onHideModal = () => {
    resetState();
    props.closeModal();
  };

  const resetState = () => {
    setRole("");
    setHierarchy(0);
    setError(false);
  }

  const disabledButton = () => role === "" || hierarchy === 0 || error;

  const disabled = disabledButton();
  
  return (
    <Modal show={props.showModal} onHide={onHideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {error && (
            <Col lg={12}>
              <Alert variant="danger" style={{position: "relative"}} onClose={() =>  { setError(false); setErrorMessage("");}} dismissible>
                {errorMessage}
              </Alert>
            </Col>
          )}
          <Col lg={6}>
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Control type="text" value={role} placeholder="Type the role name" onChange={onChangeRole} onBlur={onBlurRole} />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group>
              <Form.Label>Hierarchy</Form.Label>
              <Form.Select placeholder="select" onChange={onChangeHierarchy}>
                <option value="0">Select a role order</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <hr />

          <Col lg={12}>
            <Button className="pull-right" variant="success" onClick={onSubmitRole} disabled={disabled} >
              {hasSentData ? <SpinnerLoading /> :  "Save"}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default AddRole;