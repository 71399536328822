import { Element } from "react-scroll";
import { SectionBlock, ImgRelleno } from "@/layouts/Landing";

const Porpuse = () => 
  <Element name="porpuse">
    <SectionBlock>
      <ImgRelleno />
    </SectionBlock>
  </Element>

export default Porpuse;