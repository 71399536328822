import { Container, Row, Col } from "react-bootstrap";
import Card from "@Admin/components/Card";
import ListRoles from './listRoles';

const Roles = () =>
  <div className="content">
    <Container fluid>
      <Row>
        <Col md={12}>
          <Card title="Roles" ctTableFullWidth ctTableResponsive>
            <ListRoles />
          </Card>
        </Col>
      </Row>
    </Container>
  </div>

export default Roles;