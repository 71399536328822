import { Fragment, useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logoSidebar from "@/assets/img/logo-bco-sidebar.png";
import { Footer } from "@/layouts/Admin";
import { ActiveRoute } from "@/helpers/RouterFunctions.js";
import AdminNavbarLinks from "../Navbar/AdminNavbarLinks.js";

const Sidebar = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => setWidth(window.innerWidth);
  
  useEffect(( ) => {
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  });
  
  const sidebarBackground = {
    //backgroundImage: "url(" + this.props.image + ")"
    backgroundImage: "url(" + logoSidebar + ")"
  };

  const activeStyle = {
    textDecoration: "underline",
  };

  return (
    <>
      <Container>
        <div id="sidebar" className="sidebar">
          <div className="sidebar-background" style={sidebarBackground} />
            <div className="logo">
            <a href="#no-data" className="simple-text logo-normal">
              Tergar Admin
            </a>
          </div>
          <div className="sidebar-wrapper">
            <ul className="nav">
              {props.routes.map((prop, key) => {
                if (!prop.redirect)
                  return (
                    <Fragment key={key}>
                      <Col lg={12}>
                        <li className={ActiveRoute(prop.layout + prop.path)}>
                          <NavLink
                            to={prop.layout + prop.path}
                            className="nav-link"
                            style={({isActive}) => isActive? activeStyle : undefined}
                          >
                            <i className={prop.icon} />
                            <p>{prop.name}</p>
                          </NavLink>
                        </li>
                      </Col>
                      {prop.subpath ? prop.subpath.map((p, k) =>
                        <ul key={k}>
                          <NavLink
                            to={prop.layout + prop.path + p.path}
                            className="nav-link"
                            style={({isActive}) => isActive? activeStyle : undefined}
                          >
                            <i className={p.icon} />
                            <p>{p.name}</p>
                          </NavLink>
                        </ul>
                      ) : null}
                    </Fragment>
                  );
                return null;
              })}
              {width <= 991 ? <AdminNavbarLinks /> : null}
            </ul>
          </div>
          <Footer>
            <p>&copy; {new Date().getFullYear()} Tergar International</p>
            <p>Tergar Community Leadership</p>
          </Footer>
        </div>
      </Container>
    </>
  );
}

export default Sidebar;