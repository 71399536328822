import { useState, useContext } from "react";
import { Row, Col, Form, Button, Modal, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { MsgNotification } from "@/config/toast";
import { newslettersAPI, logsAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import { SpinnerLoading } from "@/styles/Template";

const AddNewsletter = (props) => {
  const editorStyle = {
    border: "1px solid #F1F1F1",
    height: "150px"
  };

  const user = useContext(UserContext);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [newsletterLink, setNewsletterLink] = useState("");
  const [newsletterDate, setNewsletterDate] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasSentData, setHasSentData] = useState(false);

  const onChangeTitle = e => {
    const title = e.target.value;
    setTitle(title);
  };

  const onChangeDescription = description => setDescription(description);

  const onChangeNewsletterLink = e => {
    const newsletterLink = e.target.value;
    setNewsletterLink(newsletterLink);
  };

  const onChangeNewsletterDate = e => {
    const newsletterDate = e.target.value;
    setNewsletterDate(newsletterDate);
  };

  // Send content information to API.
  const onSubmitNewsletter = async () => {
    try {
      setHasSentData(true);
      const info = {
        title,
        description: draftToHtml(convertToRaw(description.getCurrentContent())),
        newsletterLink,
        newsletterDate
      };
  
      const action_type = 'CREATE';
      const log_description = action_type + ' NEWSLETTER: ' + info.title;
      const module = 'NEWSLETTERS';
  
      const logInfo = {
        action_type,
        description: log_description,
        module,
        user: user.id
      };

      const {data: {response: createdNewsletter}} = await newslettersAPI.createNewsletter(info);

      // Create log
      await logsAPI.createLog(logInfo);

      toast(<MsgNotification msg="Newsletter has been created!" />);
      resetState();
      setHasSentData(false);
      props.closeModal(createdNewsletter);
    } catch (error) {
      setHasSentData(false);
      setError(true);
      setErrorMessage("Sorry! An error ocurred while processing your request.");
    }
  };

  const onHideModal = () => {
    resetState();
    props.closeModal();
  };

  const resetState = () => {
    setTitle("");
    setDescription(EditorState.createEmpty());
    setNewsletterLink("");
    setNewsletterDate("");
    setError(false);
  }

  const disabledButton = () =>
    title === "" ||
    draftToHtml(convertToRaw(description.getCurrentContent())) ===
      "<p></p>\n" ||
    newsletterLink === "" ||
    newsletterDate === "" ||
    error;

  const disabled = disabledButton();

  return (
    <>
      <Modal show={props.showModal} onHide={onHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add newsletter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {error && (
              <Col lg={12}>
                <Alert variant="danger" onClose={() => {setError(false); setErrorMessage("");}} dismissible>
                  {errorMessage}
                </Alert>
              </Col>
            )}
            <Col lg={12}>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" value={title} placeholder="Type a title..." onChange={onChangeTitle} autoFocus />
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Editor ariaLabel="Newsletter description" editorState={description} wrapperClassName="wrapper-class" editorClassName="editor-class" onEditorStateChange={onChangeDescription} editorStyle={editorStyle}/>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Link</Form.Label>
                <Form.Control type="text" value={newsletterLink} placeholder="Enter a link..." onChange={onChangeNewsletterLink} />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control type="date" value={newsletterDate} placeholder="Choose a date..." onChange={onChangeNewsletterDate} />
              </Form.Group>
            </Col>

            <hr />

            <Col lg={12}>
              <Button className="pull-right" variant="success" onClick={onSubmitNewsletter} disabled={disabled} >
                {hasSentData ? <SpinnerLoading /> :  "Save"}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddNewsletter;