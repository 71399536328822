
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import socket from "@/config/sockets";
import { contentsAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import { ActiveRoute, withRouter } from "@/helpers/RouterFunctions";
import { StyledSidebar, TitleMenu } from "@/styles/Template";

const Sidebar = () => {

  const user = useContext(UserContext);
  const [dataFolders, setDataFolders] = useState([]);

  useEffect(() => {
    const getDataFolders = async () => {
      try {
        let contents = await contentsAPI.getContentsByRole(user.role.id);
        setDataFolders(contents.data.response.filter(d => d.folderInfo !== null));
      } catch (error) {
        console.log(error);
      }
    }

    getDataFolders();

    socket().socket.on('contents', function(data) {
      switch(data.type){
        case 'create':
          const newData = {...data.info};
          delete newData['roles'];
          setDataFolders(prevState => [...prevState, newData]);
        break;
        case 'update':
          setDataFolders( prevState => {
            // Verify if this content already exists on dataFolders state
            const contentExists = prevState.some(content => content.id === data.info.id);
            if (contentExists) {
              // Update content data
              return prevState.filter(content => content.id === data.info.id).push(data.info); 
            } else {
              return [...prevState, data.info];
            }
          });
        break;
        case 'destroy':
          setDataFolders(prevState => [...prevState.filter(d => d.id !== data.info.id)]);
        break;
        default: 
        break;
      }
    });
  },[user]);

  return (
    <>
      <StyledSidebar className="col-3 col-lg-2 d-none d-md-block">
        <div className="list-group panel">
          <TitleMenu data-tut="reactour__role">{user ? user.role.name : ""} </TitleMenu>
          <Link to="/dashboard/" className={"list-group-item " + ActiveRoute('/dashboard/')} data-parent="#MainMenu"> Home </Link>
          {/* <Link to="/dashboard/forum" className={"list-group-item " + ActiveRoute('/dashboard/forum')} data-parent="#MainMenu"> Discussion Forum</Link> */}
          <Link to="/dashboard/newsletters" className={"list-group-item " + ActiveRoute('/dashboard/newsletters')} data-parent="#MainMenu"> Newsletters</Link>
          {
            dataFolders.length === 0 ?
            <a href="#no-data" className="list-group-item collapsed" onClick={() => {alert("Some files could not be found. Contact support to sync the files with BOX")}}>My Documents</a>
             :
            <a href="#MyDocuments" className="list-group-item collapsed" data-bs-toggle="collapse" role='button' aria-expanded='false' aria-controls="MyDocuments" data-parent="#MainMenu" data-tut="reactour__content">My Documents <span className="bi bi-chevron-down" aria-hidden="true"></span></a>
          }
          <div className="collapse" id="MyDocuments">
            <div>
              {dataFolders.map(d => (
                <Link to={{ pathname: `/dashboard/folders/${d.folderPath}`, state: { description: d.description, title: d.title } }} key={d.folderPath}>
                  {d.title}
                </Link>
              ))}
            </div>
          </div>
          {/* <Link to="/dashboard/contacts" className={"list-group-item " + ActiveRoute("/dashboard/contacts",openDocuments,openTraining)} data-parent="#MainMenu"> Contact Directory</Link> */}
          <a href="#MyTraining" className="list-group-item collapsed" data-bs-toggle="collapse" role='button' aria-expanded='false' aria-controls="MyTraining" data-parent="#MainMenu">Training <span className="bi bi-chevron-down" aria-hidden="true"></span></a>
          <div className="collapse" id="MyTraining">
            <div>
              <Link to="/dashboard/training">Online Leadership Training Module</Link>
            </div>
          </div>
          <a href="#no-data" className="list-group-item collapsed link-disabled" data-toggle="collapse" data-parent="#MainMenu">Calendar</a>
        </div>
      </StyledSidebar>
    </>
  );
}

export default withRouter(Sidebar);