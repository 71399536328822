import { useContext } from 'react';
import { TourProvider } from '@reactour/tour';
import { closeTutorial } from '@/helpers/TourFunctions';
import { UserContext } from '@/libs/context/User';

const Tour = ({ children }) => {
  const user = useContext(UserContext);

  const onClickMask = () => { return; }
  const onClickClose = ({ setIsOpen }) => closeTutorial(user, setIsOpen);

  return (
    <>
      <TourProvider 
        styles={{
          popover: (base) => ({
            ...base,
            '--reactour-accent': '#E39F24'
          })
        }}
        startAt={0}
        afterOpen={() => { document.body.style.overflow = 'hidden'; }}
        beforeClose={() => { document.body.style.overflow = 'initial'; }}
        disableInteraction
        onClickMask={onClickMask}
        onClickClose={onClickClose}
      >
        {children}
      </TourProvider>
    </>
  )
}

export default Tour;