import { useContext, useEffect, useState } from "react";
import { UserContext } from "@/libs/context/User";
import { RolesContext } from "@/libs/context/Roles";
import { hasRole, hasSession } from '@/helpers/SessionFunctions';
import {DashboardRoutes} from '@/routes/web';
import { GlobalStyle, ContentWrapper } from "@/styles/Template";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Tour from "../../components/Tour";
import TutorialMessage from "./components/TutorialMessage";


const Dashboard = () => {
  const user = useContext(UserContext);
  const [rolesContext] = useContext(RolesContext);
  const role = 'role' in user ? user.role.name : '';
  const [isMessageOpen, setMessageOpen] = useState(false);

  //Check if user made tour.
  useEffect(() => {
    let isMessageOpen;
    isMessageOpen =  !user.welcomeTourDone && (window.location.pathname !== `${process.env.PUBLIC_URL}/dashboard/` || window.location.pathname !== `${process.env.PUBLIC_URL}/dashboard`);
    setMessageOpen(isMessageOpen);
  }, [user]);

  if (!hasRole(rolesContext, role) || !hasSession()){
    window.location = process.env.REACT_APP_REDIRECT;
    return null;
  }

  return(
    <>
      <Tour>
        <GlobalStyle />
        <Header />
        <Sidebar />

        <div className="container-fluid h100">
          <ContentWrapper className="col-12 col-md-9 col-lg-10 offset-0 offset-md-3 offset-lg-2">
            <div className="wrapp-main">
              {DashboardRoutes()}
            </div>

            {isMessageOpen && <TutorialMessage user={user} setMessageOpen={setMessageOpen} />}
          </ContentWrapper>
        </div>
      </Tour>
    </>
  )
}

export default Dashboard;