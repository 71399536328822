import { useContext, useEffect, useState } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import { MsgNotification } from "@/config/toast";
import { usersAPI, rolesAPI, logsAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import countries from "@/helpers/countries.js";
import { SpinnerLoading } from '@/styles/Template';
import { SearchUsersList } from "@/layouts/Admin";

const AddUserForm = () => {
  const user = useContext(UserContext);
  const [search, setSearch] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [community, setCommunity] = useState("");
  const [position, setPosition] = useState("");
  const [accountID, setAccountID] = useState("");
  const [prefix, setPrefix] = useState("");
  const [preferredName, setPreferredName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [role, setRole] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isLeadershipActive, setIsLeadershipActive] = useState(true);
  const [roles, setRoles] = useState([]);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [disabledInputs, setDisabledInputs] = useState(false);
  const [hasSentData, setHasSentData] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
      const {data: { response: roles}} = await rolesAPI.getRoles();
      setRoles(roles);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  },[]);

  const onChangeSearch = e => {
    const search = e.target.value;
    setSearch(search);
  };

  const onBlurSearch = e => {
    const search = e.target.value.trim();

    if(search.length <= 0) return;

    setSearch(search);
  };

  const onSubmitAuth = async () => {
    //Call Gluu API.
    try {
      const {data: {response: users} } = await usersAPI.findUser(search);

      if(users.length > 0) {
        setSearchedUsers(users);
      }
      else {
        alert('No users found');
        resetState();
      }
    } catch (error) {
      if(error.response.status === 500) {
        setErrorMessage("Internal error ocurred. Please, contact with your Administrator System.");
        setError(true);
        return;
      }
      /* const { data } = error.response;

      if(data.code === "USER_NOT_FOUND_IN_GLUU") {
        setErrorMessage("The user with that email was not found in Auth System.");
        setError(true);
        return;
      } */

      setErrorMessage(error.response?.data.message);
      setError(true);
    }
  };

  const clearSearch = () => { resetState(); }

  const onChange = e => {

    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    switch(e.target.name){
      case 'name':
        setName(value);
      break;
      case 'last_name':
        setLastName(value);
      break;
      case 'email':
        setEmail(value);
      break;
      case 'community':
        setCommunity(value);
      break;
      case 'position':
        setPosition(value);
      break;
      case 'role':
        setRole(value);
      break;
      case 'accountID':
        setAccountID(value);
      break;
      case 'prefix':
        setPrefix(value);
      break;
      case 'preferredName':
        setPreferredName(value);
      break;
      case 'city':
        setCity(value);
      break;
      case 'state':
        setState(value);
      break;
      case 'country':
        setCountry(value);
      break;
      case 'isLeadershipActive':
        setIsLeadershipActive(value);
      break;
      case 'isActive':
        setIsActive(value);
      break;
      case 'isAdmin':
        setIsAdmin(value);
      break;
      default:
      break;
    }
  };

  const onBlur = e => {

    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value.trim();

    switch(e.target.name){
      case 'name':
        setName(value);
      break;
      case 'last_name':
        setLastName(value);
      break;
      case 'email':
        setEmail(value);
      break;
      case 'community':
        setCommunity(value);
      break;
      case 'position':
        setPosition(value);
      break;
      case 'role':
        setRole(value);
      break;
      case 'accountID':
        setAccountID(value);
      break;
      case 'prefix':
        setPrefix(value);
      break;
      case 'preferredName':
        setPreferredName(value);
      break;
      case 'city':
        setCity(value);
      break;
      case 'state':
        setState(value);
      break;
      case 'country':
        setCountry(value);
      break;
      case 'isLeadershipActive':
        setIsLeadershipActive(value);
      break;
      case 'isActive':
        setIsActive(value);
      break;
      case 'isAdmin':
        setIsAdmin(value);
      break;
      default:
      break;
    }
  };

  const onSubmitUser = async () => {
    setHasSentData(true);

    const info = {
      name,
      last_name,
      email,
      community,
      position,
      accountID,
      prefix,
      preferredName,
      city,
      state,
      country,
      role,
      isAdmin,
      isActive,
      isLeadershipActive
    };

    const action_type = 'CREATE';
    const description = action_type + ' USER: ' + info.name + ' ' + info.last_name;
    const module = 'USERS';

    const logInfo = {
      action_type,
      description,
      module,
      user: user.id
    };

    try {
      // Create log
      await logsAPI.createLog(logInfo);

      // Create user
      await usersAPI.createUser(info);

      toast(<MsgNotification msg="User has been created!" />);
      resetState();
      setHasSentData(false);
    } catch (error) {

      if (error?.response) {
        if(error.response.status === 509){
          setErrorMessage(error.response.message);
        }
        else{
          setErrorMessage('Sorry! An error ocurred while processing your request.');
        }
        setError(true);
      }else{
        console.error(error);
      }
      setHasSentData(false);
    }
  };

  const resetState = () =>{
    setName("");
    setLastName("");
    setEmail("");
    setCommunity("");
    setPosition("");
    setAccountID("");
    setPrefix("");
    setPreferredName("");
    setCity("");
    setState("");
    setCountry("");
    setRole("");
    setIsAdmin(false);
    setIsActive(true);
    setIsLeadershipActive(true);
    setError(false);
    setErrorMessage("");
    setSearch("");
    setDisabledInputs(false);
    setSearchedUsers([]);
  }

  const handleSearch = (userInfo) => {
    setName(userInfo.name);
    setLastName(userInfo.lastName);
    setEmail(userInfo.email);
    setCommunity("");
    setPosition("");
    setAccountID("");
    setPrefix("");
    setPreferredName("");
    setCity("");
    setState("");
    setCountry("");
    setRole("");
    setIsAdmin(false);
    setIsActive(true);
    setIsLeadershipActive(true);
    setError(false);
    setErrorMessage("");
    setSearch("");
    setDisabledInputs(true);
    setSearchedUsers([]);
  }

  const disabledSubmitAuth = error || search.length <= 0;
  const disabledAddUser =
    name === "" ||
    last_name === "" ||
    email === "" ||
    community === "" ||
    position === "" ||
    accountID === "" ||
    prefix === "" ||
    preferredName === "" ||
    city === "" ||
    state === "" ||
    country === "" ||
    role === "";

    return (
    <>
      <Col lg={12}>
        <Row>
          {error && (
            <Col lg={12}>
              <Alert variant="danger" onClose={() => { setError(false); setErrorMessage("");}} dismissible>
                {errorMessage}
              </Alert>
            </Col>
          )}
          <Col lg={4}>
            <Form.Group>
              <Form.Label>Search</Form.Label>
              <Form.Control type="text" value={search} placeholder="Type an email..." onChange={onChangeSearch} onBlur={onBlurSearch} autoFocus />
            </Form.Group>
              
            <ul className="nav navbar-nav search-input">
              {searchedUsers.length > 0 && (
                <SearchUsersList>
                  {searchedUsers.map((user,index) => (<p key={index} onClick={() => handleSearch(user)}>{user.email}</p>))}
                </SearchUsersList>
              )}
            </ul>
          </Col>
          <Col lg={4}>
            <Button variant="success" style={{ marginTop: "24px" }} onClick={clearSearch} disabled={disabledSubmitAuth} >
              Clear search
            </Button>
          </Col>

          <div className="clearfix"></div>

          <Col lg={4}>
            <Button variant="success" style={{ marginTop: "23px" }} onClick={onSubmitAuth} disabled={disabledSubmitAuth} >
              Find user in Auth System
            </Button>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col lg={3}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" placeholder="Type a name..." value={name} onChange={onChange} onBlur={onBlur} disabled={disabledInputs} />
            </Form.Group>
          </Col>
            
          <Col lg={3}>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" name="last_name" placeholder="Type a last name..." value={last_name} onChange={onChange} onBlur={onBlur} disabled={disabledInputs} />
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" name="email" placeholder="Type an email..." value={email} onChange={onChange} onBlur={onBlur} disabled={disabledInputs} />
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group>
              <Form.Label>Community</Form.Label>
              <Form.Control type="text" name="community" placeholder="Type a community..." value={community} onChange={onChange} onBlur={onBlur} />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col lg={3}>
            <Form.Group>
              <Form.Label>Position</Form.Label>
              <Form.Control type="text" name="position" placeholder="Type a position..." value={position} onChange={onChange} onBlur={onBlur} />
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group>
              <Form.Label>Account ID</Form.Label>
              <Form.Control type="text" name="accountID" placeholder="Type an account ID..." value={accountID} onChange={onChange} onBlur={onBlur} />
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group>
              <Form.Label>Prefix</Form.Label>
              <Form.Control type="text" name="prefix" placeholder="Type a prefix..." value={prefix} onChange={onChange} onBlur={onBlur} />
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group>
              <Form.Label>Preferred name</Form.Label>
              <Form.Control type="text" name="preferredName" placeholder="Type a preferred name..." value={preferredName} onChange={onChange} onBlur={onBlur} />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col lg={3}>
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control type="text" name="city" placeholder="Type a city..." value={city} onChange={onChange} onBlur={onBlur} />
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group>
              <Form.Label>State</Form.Label>
              <Form.Control type="text" name="state" placeholder="Type a state..." value={state} onChange={onChange} onBlur={onBlur} />
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group>
              <Form.Label>Country</Form.Label>
              <Form.Select name="country" placeholder="select" aria-label="country" value={country} onChange={onChange} >
                <option value="">Select a country...</option>
                {Object.entries(countries).map(([key, value]) => (<option value={value} key={key}>{value}</option>))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Select name="role" placeholder="select" aria-label="role" value={role} onChange={onChange} >
                <option value="">Select a role...</option>
                {roles.map(d => (<option value={d.id} key={d.id}>{d.name}</option>))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col lg={6} style={{ marginTop: "23px" }}>
            <Form.Check type='checkbox' label='Is Admin?' name="isAdmin" role="isAdmin" inline checked={isAdmin} onChange={onChange} />
            <Form.Check type='checkbox' label='Is Active?' name="isActive" role="isActive" inline checked={isActive} onChange={onChange} />
            <Form.Check type='checkbox' label='Is Leadership Active?' name="isLeadershipActive" role="isLeadershipActive" inline checked={isLeadershipActive} onChange={onChange} />
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Button className="pull-right" variant="success" style={{ marginTop: "23px" }} disabled={disabledAddUser} onClick={onSubmitUser} >
              {hasSentData ? <SpinnerLoading /> :  "Add user"}
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default AddUserForm;