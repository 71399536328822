import http from "@/config/http";

/*************** <NEWSLETTER> *********************/
export const getNewsletters = async () => http.get(`v1/newsletters`);

export const createNewsletter = async (data) => http.post(`v1/newsletters`, data);

export const updateNewsletter = async (id, data) => http.put(`v1/newsletters/${id}`, data);

export const deleteNewsletter = async (id) => http.delete(`v1/newsletters/${id}`);
/*************** <NEWSLETTER/> *********************/