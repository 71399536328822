import styled from "styled-components";
import NotificationImg from '../assets/img/var/notification.svg';

const MsgToastImg = styled.img`
  width:30px;
  display: inline-block;
  vertical-align: middle;
`;

const MsgToastTxt = styled.p`
  width: calc(100% - 36px);
  display: inline-block;
  vertical-align: middle;
  padding-left: 6px;
  margin: 0;
  color: #727070;
`;

export const toastConfig = {
  position: "top-right",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const MsgNotification = ({msg}) => (
  <div>
    <MsgToastImg src={NotificationImg}/>
    <MsgToastTxt>{msg}</MsgToastTxt>
  </div>
);