import { useTour } from "@reactour/tour";
import { BackBlack, CenterHorizontal, CenterVertical, BoxMessage, LegendBanner } from "@/styles/Template";
import BannerTutorial from '@/assets/img/var/banner.jpg';
import BannerTutorial2 from '@/assets/img/var/banner2.jpg';
import { closeTutorial, openTutorial } from "@/helpers/TourFunctions";

const TutorialMessage = ({ user, setMessageOpen }) => {
  const { setIsOpen,  setSteps, setCurrentStep } = useTour();

  return (
    <BackBlack>
      <CenterHorizontal>
        <CenterVertical>
            <BoxMessage className="text-right">
            <img src={BannerTutorial} alt="Banner Tutorial" className="d-none d-md-block"/>
            <img src={BannerTutorial2} alt="Banner Tutorial 2" className="d-block d-md-none"/>
            <LegendBanner>
              <h2>Community Leadership & Training Area</h2>
              <a href="#no-data" onClick={() => openTutorial(user, setIsOpen, setSteps, setCurrentStep, setMessageOpen)}>Take a tour</a>
            </LegendBanner>
            <a href="#no-data" onClick={() => closeTutorial(user, setIsOpen, setMessageOpen)}>Skip &rarr;</a>
            </BoxMessage>
        </CenterVertical>
      </CenterHorizontal>
    </BackBlack>
  )
}

export default TutorialMessage;