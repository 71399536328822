import { useContext, useState } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MsgNotification } from "@/config/toast";
import { contactsAPI, logsAPI } from '@/libs/api';
import { UserContext } from '@/libs/context/User';
import communities from "@/helpers/communities.js"
import { SpinnerLoading } from '@/styles/Template';

const EditContact = (props) => {

  const user = useContext(UserContext);
  let [name, setName] = useState('');
  let [email, setEmail] = useState('');
  let [position, setPosition] = useState('');
  let [description, setDescription] = useState('');
  let [community, setCommunity] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isChangeName, setIsChangeName] = useState(false);
  const [isChangeEmail, setIsChangeEmail] = useState(false);
  const [isChangePosition, setIsChangePosition] = useState(false);
  const [isChangeDescription, setIsChangeDescription] = useState(false);
  const [isChangeCommunity, setIsChangeCommunity] = useState(false);
  const [hasSentData, setHasSentData] = useState(false);

  const onChangeName = e => {
    const name = e.target.value;
    setName(name);
    setIsChangeName(true);
  }

  const onBlurName = e => {
    const name = e.target.value.trim();
    const isChangeName = name !== '' && name !== props.data.name;
    setName(name);
    setIsChangeName(isChangeName);
  }

  const onChangeEmail = e => {
    const email = e.target.value;
    setEmail(email);
    setIsChangeEmail(true);
  }

  const onBlurEmail = e => {
    const email = e.target.value.trim();
    const isChangeEmail = email !== '' && email !== props.data.email;
    setEmail(email);
    setIsChangeEmail(isChangeEmail);
  }

  const onChangePosition = e => {
    const position = e.target.value;
    setPosition(position);
    setIsChangePosition(true);
  }

  const onBlurPosition = e => {
    const position = e.target.value.trim();
    const isChangePosition = position !== '' && position !== props.data.position;
    setPosition(position);
    setIsChangePosition(isChangePosition);
  }

  const onChangeDescription = e => {
    let description = e.target.value;
    setDescription(description);
    setIsChangeDescription(true);
  }

  const onBlurDescription = e => {
    const description = e.target.value.trim();
    const isChangeDescription = description !== '' && description !== props.data.description;
    setDescription(description);
    setIsChangeDescription(isChangeDescription);
  };

  const onChangeCommunity = e => {
    const community = e.target.value;
    const isChangeCommunity = community !== props.data.community && community;
    setCommunity(community);
    setIsChangeCommunity(isChangeCommunity);
  }

  const onSubmitContact = async () => {
    try {
      setHasSentData(true);

      const name2 = isChangeName ? name : props.data.name;
      const email2 = isChangeEmail ? email : props.data.email;
      const position2 = isChangePosition ? position : props.data.position;
      const description2 = isChangeDescription ? description : props.data.description;
      const community2 = isChangeCommunity ? community : props.data.community;
  
      const info = {
        contact: {
          name: name2,
          email: email2,
          position: position2,
          description: description2,
          community: community2
        }
      }
  
      const action_type = 'UPDATE';
      const log_description = action_type + ' CONTACT: ' + info.contact.name;
      const module = 'CONTACTS';
  
      const logInfo = {
        action_type,
        description: log_description,
        module,
        user: user.id
      };

      const {data: {response: updatedContact}} = await contactsAPI.updateContact(props.data.id, info);

      // Create log
      await logsAPI.createLog(logInfo);

      toast(<MsgNotification msg='Contact has been updated!' />);
      
      resetState();
      setHasSentData(false);
      props.closeModal(updatedContact);
    } catch (error) {
      setHasSentData(false);
      setError(true);
      setErrorMessage("Sorry! An error ocurred while processing your request.");
    }
  }

  const onHideModal = () => {
    resetState();
    props.closeModal();
  }

  const resetState = () => {
    setName("");
    setEmail("");
    setPosition("");
    setDescription("");
    setCommunity("");
    setError(false);
    setErrorMessage("");
  }

  const disabledButton = () => (!isChangeName && !isChangeEmail && !isChangePosition && !isChangeDescription && !isChangeCommunity) || error;

  const disabled = disabledButton();
  name = isChangeName ? name : props.data.name;
  email = isChangeEmail ? email : props.data.email;
  position = isChangePosition ? position : props.data.position;
  description = isChangeDescription ? description : props.data.description;
  community = isChangeCommunity ? community : props.data.community;

  return (
    <>
      <Modal show={props.showModal} onHide={onHideModal}>
        <Modal.Header closeButton>
            <Modal.Title>Edit contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {error &&
              <Col lg={12}>
                <Alert variant="danger" onClose={() =>  { setError(false); setErrorMessage("");}} dismissible>
                  {errorMessage}
                </Alert>
              </Col>
            }
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" value={name} placeholder="Type a name" onChange={onChangeName} onBlur={onBlurName} />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" value={email} placeholder="Type an email" onChange={onChangeEmail} onBlur={onBlurEmail} />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Position</Form.Label>
                <Form.Control type="text" value={position} placeholder="Type a position" onChange={onChangePosition} onBlur={onBlurPosition} />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Community</Form.Label>
                <Form.Select placeholder="select" onChange={onChangeCommunity} value={community}>
                  <option value="">Select a community</option>
                  {Object.entries(communities).map(([key, value]) => (<option value={value} key={key}>{value}</option>))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" placeholder="Type a description" rows="8" value={description} onChange={onChangeDescription} onBlur={onBlurDescription} />
              </Form.Group>
            </Col>
            <hr />
            <Col lg={12}>
              <Button className="pull-right" variant="success" onClick={onSubmitContact} disabled={disabled}>
                {hasSentData ? <SpinnerLoading /> :  "Save"}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditContact;