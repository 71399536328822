import http from "@/config/http";

/*************** <USERS> *********************/
export const getUsers = async () => http.get(`v1/users`);

export const findUser = async (query) => http.get(`v1/users/search?query=${query}`);

export const createUser = async (data) => http.post(`v1/users`, data);

export const getUser = async (id) => http.get(`v1/users/${id}`);

export const updateTutorial = async (id) => http.put(`v1/users/${id}/tutorial`, null);

export const importUsers = async (data) => http.post(`v1/users/import`, data);

export const updateUser = async (id, data) => http.put(`v1/users/${id}`, data);

export const disableUser = async (id) => http.put(`v1/users/${id}/disable`, '');

/*************** <USERS/> *********************/