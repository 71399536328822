import axios from "axios";
import { getCookie, setCookie, deleteCookie } from "@/helpers/SessionFunctions";
import { sessionsAPI } from "@/libs/api";

let isRefreshing = false;
let refreshingToken = [];
const onTokenRefreshed = (token) => {
  refreshingToken.map(t => t(token))
};
const suscribeToken = (t) => {
  refreshingToken.push(t);
}

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

http.interceptors.request.use( request => {
  request.headers.Authorization = `Bearer ${getCookie('token')}`;
  return request;
});

http.interceptors.response.use( response => response, async error => {
  const originalRequest = error.config;
  //If session expired then we refresh the token and continue original request.
  if (error.response?.status === 401) {
    try {
      if(!isRefreshing){
        isRefreshing = true;
        //Update token
        const {data: {response: tokenResponse}} = await sessionsAPI.refresh({token: getCookie('token')});
        isRefreshing = false;
        //Update cookie.
        setCookie('token',tokenResponse);
        onTokenRefreshed(tokenResponse);
        //Add to original request
        originalRequest.headers['Authorization'] = `Bearer ${tokenResponse}`; 
      }

      //Return riginal request.
      return new Promise(resolve => {
        suscribeToken(accessToken => {
          //Add to original request
          originalRequest.headers['Authorization'] = `Bearer ${accessToken}`; 
          resolve(http(originalRequest))
        });
      });
    } catch (updateErr) {
      error = validateResponseErrorMessage(updateErr); 
    }
  } else {
    error = validateResponseErrorMessage(error);
  }

  return Promise.reject(error);
}

);

const validateResponseErrorMessage = (error) => {
  if (error && error.response) {
    let response = error.response;
    //Check if there isn't session in API.
    if (response.status === 403) {
      error.message = 'Expired session';
      deleteCookie('token');
      window.location = process.env.REACT_APP_REDIRECT;
    } else {
      error.message = `There is an internal error on server. Please, try again later! <br/> Error: ${JSON.stringify(response.data)}`;
    }
  } else {
    error.message = "Network error. Try again!";
    alert( "Network error. Try again!");
  }

  return error;
};

export default http;