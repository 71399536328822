import { Container, Row, Col } from "react-bootstrap";
import Card from "@Admin/components/Card";
import AddUserForm from "./addUserForm";

const AddUser = () =>
<div className="content">
  <Container fluid>
    <Row>
      <Col md={12}>
        <Card title="Users" ctTableFullWidth ctTableResponsive>
          <AddUserForm />  
        </Card>
      </Col>
    </Row>
  </Container>
</div>

export default AddUser;