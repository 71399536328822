
import {useLocation, useNavigate, useParams} from 'react-router-dom';

export const ActiveRoute = (routeName) => {
  const location = useLocation();

  if(location.pathname.match(/\//g).length === 1)
    location.pathname = location.pathname + '/';
  
  return routeName === location.pathname ? "active" : "";
};

export function withRouter (Component){
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export const getDataFromURL = () => {
  const url = new URL(window.location.href);
  const code = url.searchParams.get("code");
  const session_state = url.searchParams.get("session_state");

  return {code, session_state};
};