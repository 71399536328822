import React from "react";
import ReactDOM from "react-dom";
import { MainRoutes as Main } from "./routes/web";
import "./boot";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from "./auth0ProviderWithNavigate";

ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Auth0ProviderWithNavigate>
          <Main />
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </React.StrictMode>,
  document.getElementById("app")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
