import { createContext, useState, useEffect } from "react";
import { rolesAPI } from '@/libs/api';

export const RolesContext = createContext([]);

// This context provider is passed to any component requiring the context
export const RolesProvider= ({ children }) => {
  const [rolesContext, setRoles] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        //Get roles.
        const result = await rolesAPI.getRoles();
        const roles = result.data.response.map(r => r.name);
        setRoles([roles]);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  },[]);

  return (
    <RolesContext.Provider value={rolesContext}>
      {!isLoading && children}
    </RolesContext.Provider>
  );
};
