import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { UserContext } from "../libs/context/User";
import { RolesProvider } from "../libs/context/Roles";
import { getUserInfo } from '../helpers/SessionFunctions';
import Login from "../views/Landing/components/Login";
import Logout from "../views/Landing/components/Logout";
import Landing from "../views/Landing";
import Dashboard from "../views/Dashboard";
import Activity from "../views/Dashboard/sections/Activity";
import Files from "../views/Dashboard/sections/Files";
import Preview from "../views/Dashboard/sections/Files/preview";
import Newsletters from "../views/Dashboard/sections/Newsletters";
/* import Forums from "../views/Dashboard/sections/Forums";
import Contacts from "../views/Dashboard/sections/Contacts";*/
import Training from "../views/Dashboard/sections/Training";
import Admin from "../views/Admin";
import UsersAdmin from "../views/Admin/sections/Users";
import AddUsersAdmin from "../views/Admin/sections/Users/addUser";
import ContentsAdmin from "../views/Admin/sections/Contents";
import RolesAdmin from "../views/Admin/sections/Roles";
import NewslettersAdmin from "../views/Admin/sections/Newsletters";
import ContactsAdmin from "../views/Admin/sections/Contacts";
import NotFound from '../views/Admin/components/NotFound';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '@/components/Loading';

export const MainRoutes = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  console.log("Is authenticated", isAuthenticated);

  if (isLoading) {
    return <Loading/>
  }


  return (
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route path="/getuserinfo" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/admin/*" element={
          <UserContext.Provider value={getUserInfo(isAuthenticated)} >
            <RolesProvider>
              <Admin />
              <ToastContainer />
            </RolesProvider>
          </UserContext.Provider>
        } />
        <Route path="/dashboard/*" element={
          <UserContext.Provider value={getUserInfo(isAuthenticated)} >
            <RolesProvider>
              <Dashboard />
              <ToastContainer />
            </RolesProvider>
          </UserContext.Provider>
        } />
    </Routes>
  );
};

export const DashboardRoutes = () => {
  return (
    <Routes>
        {/*<Route path="/dashboard/forum" element={Forums} />*/}
        <Route exact path="/" element={<Activity />} />
        <Route path="folders/:folderID" element={<Files />} />
        <Route path="files/preview/:fileID" element={<Preview />} />
        <Route path="newsletters" element={<Newsletters />} />
        {/* <Route path="/dashboard/contacts" element={Contacts} /> */}
        <Route path="training" element={<Training />} />
    </Routes>
  );
}

export const AdminRoutes = () => {
  return(
    <Routes>
      <Route path="users" element={<UsersAdmin />} />
      <Route path="users/add" element={<AddUsersAdmin />} />
      <Route path="contents" element={<ContentsAdmin />} />
      <Route path="roles" element={<RolesAdmin />} />
      <Route path="newsletters" element={<NewslettersAdmin />} />
      <Route path="contacts" element={<ContactsAdmin />} />
      {/*<Route exact path={"/admin/users/import"} render={props => <ImportUsers {...props} />} /> */}
      {/*<Route exact path={"/admin/contacts/import"} render={props => <ImportContacts {...props} />} />*/}
      {/*<Route exact path={"/admin/settings/email"} render={props => <Emails {...props} />} /> */}
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export const adminRoutes = [
    {
      path: "",
      name: "Dashboard",
      icon: "pe-7s-angle-left-circle",
      layout: "/dashboard",
    },
    {
      path: "/users",
      name: "Users",
      icon: "pe-7s-users",
      layout: "/admin"
    },
    {
      path: "/users/add",
      name: "Add user",
      icon: "pe-7s-users",
      layout: "/admin"
    },
    {
      path: "/roles",
      name: "Roles",
      icon: "pe-7s-key",
      layout: "/admin"
    },
    {
      path: "/contents",
      name: "Content",
      icon: "pe-7s-notebook",
      layout: "/admin"
    },
    {
      path: "/newsletters",
      name: "Newsletter",
      icon: "pe-7s-news-paper",
      layout: "/admin"
    },
    {
      path: "/contacts",
      name: "Contacts",
      icon: "pe-7s-user",
      layout: "/admin"
    }
  ];