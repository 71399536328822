import { useContext, useEffect, useState } from "react";
import Loading from '@/components/Loading';
import { UserContext } from "@/libs/context/User";
import { TitleH2 } from "@/styles/Template";

const Training = () => {

  const user = useContext(UserContext);
  const [isLoading, setLoading] = useState(true);
  const [iframeURL, setIframeURL] = useState("");

  useEffect(() => {
    
    const actor = JSON.stringify({
      mbox: "mailto:" + user.email,
      name: user.name + " " + user.last_name,
      objectType: "Agent",
    });

    const authBasic = btoa(process.env.REACT_APP_LRS_KEY + ":" + process.env.REACT_APP_LRS_SECRET);

    // Build iframe URL
    const iframeURL = encodeURI(
      process.env.REACT_APP_XAPI_URL + "?" + 
      "actor=" + actor +
      "&auth=Basic " + authBasic +
      "&endpoint=" + process.env.REACT_APP_LRS_XAPI_ENDPOINT +
      "&registration=" + process.env.REACT_APP_REGISTRATION_UUID +
      "&activity_id=" + process.env.REACT_APP_XAPI_ID +
      "&v=" + Date.now()
    );

    setIframeURL(iframeURL);
    setLoading(false);
  },[user]);

  return (
    <>
      {isLoading ? 
        <Loading />
        :
        <div className="row mt-4 h100">
          <div className="col-12">
            <TitleH2>Online Leadership Training Module</TitleH2>
          </div>
          <iframe
            title="Training"
            src={iframeURL}
            webkitallowfullscreen=""
            mozallowfullscreen=""
            allowFullScreen=""
            width="100%"
            height="100%"
          ></iframe>
        </div>
      }
    </>
  );
}

export default Training;