import { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { BtnDownload, BreadCrumbs, TitleH2 } from "@/styles/Template";
import { formatBytes, getFileIcon } from "@/helpers/MainFunctions";
import { dateFormat } from "@/helpers/TimeFunctions";
import { contentsAPI, filesAPI, foldersAPI, activitiesAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import Loading from "@/components/Loading";

const Files = () => {

  let { folderID } = useParams();
  const user = useContext(UserContext);
  const [dataFolder, setDataFolders] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [path_collection, setPathCollection] = useState([]);
  const [currentFolder, setCurrentFolder] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getFolders = async () => {
      try {
        const dataFolder = await foldersAPI.getFolderContentByRole(user.role.id, folderID);
        const { path_collection, name, id, item_collection } = dataFolder.data.response;
        const currentFolder = { name, id };
        const dataContent = await contentsAPI.getContentsByRole(user.role.id);
  
        //Change folderID if the user is searching between subfolders.
        let pathID = folderID;
        if (path_collection.total_count > 2) {
          pathID = path_collection?.entries[2].id;
        }

        setDataItems(item_collection.entries);
        setPathCollection(path_collection);
        setCurrentFolder(currentFolder);
        setDataFolders(dataContent.data.response.filter(d => d.folderPath === pathID && d.folderInfo !== null));
        /* this.setState({
          pathname: url,
        }); */
      } catch (error) {
        console.log(error);  
      }
    };

    getFolders()
    .then(() => setLoading(false));
  },[user,folderID]);

  const handleDownload = async (id, name) => {
    try {
      const { data: { response } } = await filesAPI.getFileDownloadLink(id);
      let element = document.createElement("a");
      element.setAttribute("href", response);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      const info = {
        description: "You downloaded a file: " + name,
        fileName: name,
        action: "download",
        link: "/dashboard/files/preview/" + id,
        user: user.id,
        folderId: folderID
      };

      await activitiesAPI.createActivity(info);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="row">
          <TitleH2 className="mt-4 d-none d-md-block">
            {dataFolder.length > 0 ? dataFolder[0].title + " " : ""}
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip">{dataFolder.length > 0 ? dataFolder[0].description : ""}</Tooltip>}
            >
            <span className="bi bi-info-circle-fill" aria-hidden="true"></span>
            </OverlayTrigger>
          </TitleH2>

          {path_collection.total_count === 2 &&
            <BreadCrumbs>
              {dataFolder[0].title} /
            </BreadCrumbs>}
          {path_collection.total_count === 3 &&
            <BreadCrumbs>
              <Link to={`/dashboard/folders/${dataFolder[0].folderPath}`}>{dataFolder[0].title}</Link> / {currentFolder.name}
            </BreadCrumbs>}
          {path_collection.total_count > 3 &&
            <BreadCrumbs>
              <span className="bi bi-three-dots" aria-hidden="true"></span>
              <Link to={`/dashboard/folders/${path_collection.entries[path_collection.total_count-1].id}`}>{path_collection.entries[path_collection.total_count-1].name}</Link> / {currentFolder.name}
            </BreadCrumbs>}

          <table
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            className="mt26 table-detail-file"
          >
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col" className="hidden-xs">
                  Modified
              </th>
                <th scope="col" className="hidden-xs">
                  Size
              </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {dataItems.map(d => (
                <tr className="archive" key={d.id}>
                  <td>
                  {d.type === "web_link" ? <a href={d.url} target="_blank" className="weblink" rel="noreferrer">{d.name}</a> : 
                    <Link
                      to={
                        d.type === "file"
                          ? `/dashboard/files/preview/${d.id}`
                          : `/dashboard/folders/${d.id}`
                      }
                      className={
                        d.type === "file" ? getFileIcon(d.name) : "folderfile"
                      }
                    >
                      {d.name}
                    </Link>
                  }
                  </td>
                  <td className="hidden-xs">
                    {dateFormat(d.info.modified_at, "DD/MM/YYYY, HH:mm:ss")}
                  </td>
                  <td className="hidden-xs">{d.type === "file" || d.type === "folder" ? formatBytes(d.info.size) : ""}</td>
                  <td>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip">Download</Tooltip>}
                    >
                      <BtnDownload
                        className={d.type === "file" ? "" : "hidden"}
                        onClick={() => handleDownload(d.id, d.name)}
                      >
                        <i className="pe-7s-cloud-download"></i>
                      </BtnDownload>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        )}
    </>
  );
}

export default Files;