import { useAuth0 } from "@auth0/auth0-react";
import { BtnLoginGoto  } from "@/layouts/Landing";

const LoginButton = ({label}) => {
    const { loginWithRedirect } = useAuth0();

    return <BtnLoginGoto 
        onClick={() => loginWithRedirect()}>{label}</BtnLoginGoto>;
};

export default LoginButton;