import { useContext, useEffect, useState } from "react";
import { Row, Col, Button, Table, /* Pagination */ } from "react-bootstrap";
import { toast } from "react-toastify";
import { MsgNotification } from "@/config/toast";
import { newslettersAPI, logsAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import { NoDataMessage } from "@/layouts/Admin";
import Search from "@Admin/components/Search";
import AddNewsletter from "./addNewsletter";
import EditNewsletter from "./editNewsletter";

const ListNewsletter = () => {

  const user = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  let [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editData, setEditData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const {data: {response: newsletter}} = await newslettersAPI.getNewsletters();
        setData(newsletter);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);

  const handleShowModal = () => setShowModal(true);

  const handleShowEditModal = e => {
    const editData = data.filter( d => d.id === e.currentTarget.dataset.id );
    setShowEditModal(true);
    setEditData(editData);
  };

  const handleCloseModal = info => {
    if(info !== undefined) {
      setShowModal(false);
      setData(() => ([...data, info]));
    }else{
      setShowModal(false);
    }
  }

  const handleCloseEditModal = info => {
    if (info !== undefined) {
      const info2 = data.map(d => d.id === info.id ? info : d);

      setShowEditModal(false);
      setData([...info2]);
    } else {
      setShowEditModal(false);
    }
  };

  const removeNewsletter = async id => {
    try {
      // Get newsletter info
      const newsletter = data.find(d => d.id === id);
      const action_type = 'DELETE';
      const description = action_type + ' NEWSLETTER: ' + newsletter.title;
      const module = 'NEWSLETTERS';

      const logInfo = {
        action_type,
        description,
        module,
        user: user.id
      };
      await newslettersAPI.deleteNewsletter(id);

      // Create log
      await logsAPI.createLog(logInfo);

      toast(<MsgNotification msg="Newsletter has been deleted!" />);
      const info = data.filter(d => d.id !== id);
      setData(info);
    } catch (error) {
      toast.error("Newsletter not deleted!");
    }
  };

  data = isSearching? filteredData: data;

  return (
      <>
        <Col lg={12}>
          <Row>
            <Col lg={6}>
              <Button variant="success" style={{ marginTop: "23px" }} onClick={handleShowModal} >
                <i className="pe-7s-plus"></i> Add newsletter
              </Button>
            </Col>
            <Col lg={6} className="pull-right">
              <Search data={data} attribute="newsletterDate" text="Type a date..." filteredData={(filteredData, isSearching) => {setFilteredData(filteredData); setIsSearching(isSearching);}} />
            </Col>
          </Row>
          {data.length > 0 ? (
            <Table striped bordered responsive hover style={{ marginTop: "23px" }} >
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Link</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map(d => (
                  <tr key={d.id}>
                    <td>{d.title}</td>
                    <td>
                      <a href={d.newsletterLink} target="_blank" rel="noreferrer">
                        {d.newsletterLink}
                      </a>
                    </td>
                    <td>{d.newsletterDate}</td>
                    <td>
                      <Button variant="warning" onClick={handleShowEditModal} data-id={d.id} >
                        <i className="pe-7s-pen"></i> Edit
                      </Button>
                      <Button variant="danger" onClick={() => {
                          const r = window.confirm("Are you sure you want to delete this item?");
                          if (r) removeNewsletter(d.id);
                        }}
                      >
                        <i className="pe-7s-trash"></i> Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : isSearching ? (
            <NoDataMessage>
              No results found.
              <br />
              Try adjusting your search to find what you're looking
              for.
            </NoDataMessage>
          ) : (
            <NoDataMessage>
              It looks like you don't have any information yet.{" "}
              <br />
              Once you add information, this page will show it.{" "}
            </NoDataMessage>
          )}

          {/*<div className="text-center">
            <Pagination>
                <Pagination.First />
                <Pagination.Prev />
                <Pagination.Item>{1}</Pagination.Item>
                <Pagination.Ellipsis />

                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Item>{11}</Pagination.Item>
                <Pagination.Item active>{12}</Pagination.Item>
                <Pagination.Item>{13}</Pagination.Item>
                <Pagination.Item disabled>{14}</Pagination.Item>

                <Pagination.Ellipsis />
                <Pagination.Item>{20}</Pagination.Item>
                <Pagination.Next />
                <Pagination.Last />
            </Pagination>
          </div>*/}
        </Col>
        <AddNewsletter
          data={data}
          showModal={showModal}
          closeModal={handleCloseModal}
        />
        {showEditModal && (
          <EditNewsletter
            data={editData[0]}
            showModal={showEditModal}
            closeModal={handleCloseEditModal}
          />
        )}
    </>
  )
}

export default ListNewsletter;