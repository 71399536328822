import { useContext, useEffect, useState } from "react";
import { Row, Col, Form, Button, Modal, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import { MsgNotification } from "@/config/toast";
import { usersAPI, rolesAPI, logsAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import countries from "@/helpers/countries.js";
import { SpinnerLoading } from "@/styles/Template";

const EditUser = (props) => {
  const user = useContext(UserContext);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let [name, setName] = useState("");
  let [lastName, setLastName] = useState("");
  let [community, setCommunity] = useState("");
  let [position, setPosition] = useState("");
  let [accountID, setAccountID] = useState("");
  let [prefix, setPrefix] = useState("");
  let [preferredName, setPreferredName] = useState("");
  let [city, setCity] = useState("");
  let [state, setState] = useState("");
  let [country, setCountry] = useState("");
  let [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [isChangeName, setIsChangeName] = useState(false);
  const [isChangeLastName, setIsChangeLastName] = useState(false);
  const [isChangeCommunity, setIsChangeCommunity] = useState(false);
  const [isChangePosition, setIsChangePosition] = useState(false);
  const [isChangeAccountID, setIsChangeAccountID] = useState(false);
  const [isChangePrefix, setIsChangePrefix] = useState(false);
  const [isChangePreferredName, setIsChangePreferredName] = useState(false);
  const [isChangeCity, setIsChangeCity] = useState(false);
  const [isChangeState, setIsChangeState] = useState(false);
  const [isChangeCountry, setIsChangeCountry] = useState(false);
  const [isChangeRole, setIsChangeRole] = useState(false);
  const [hasSentData, setHasSentData] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const {
          data: { response: roles },
        } = await rolesAPI.getRoles();
        setRoles(roles);
        /* eslint-disable-next-line */
      } catch (error) {}
    };

    getData();
  }, []);

  const onChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    switch (e.target.name) {
      case "name":
        if (value === props.data.name) {
          setIsChangeName(false);
        } else {
          setIsChangeName(true);
        }
        setName(value);
        break;
      case "last_name":
        if (value === props.data.last_name) {
          setIsChangeLastName(false);
        } else {
          setIsChangeLastName(true);
        }
        setLastName(value);
        break;
      case "community":
        if (value === props.data.community) {
          setIsChangeCommunity(false);
        } else {
          setIsChangeCommunity(true);
        }
        setCommunity(value);
        break;
      case "position":
        if (value === props.data.position) {
          setIsChangePosition(false);
        } else {
          setIsChangePosition(true);
        }
        setPosition(value);
        break;
      case "role":
        if (value === props.data.role.id) {
          setIsChangeRole(false);
        } else {
          setIsChangeRole(true);
        }
        setRole(value);
        break;
      case "accountID":
        if (value === props.data.accountID) {
          setIsChangeAccountID(false);
        } else {
          setIsChangeAccountID(true);
        }
        setAccountID(value);
        break;
      case "prefix":
        if (value === props.data.prefix) {
          setIsChangePrefix(false);
        } else {
          setIsChangePrefix(true);
        }
        setPrefix(value);
        break;
      case "preferredName":
        if (value === props.data.preferredName) {
          setIsChangePreferredName(false);
        } else {
          setIsChangePreferredName(true);
        }
        setPreferredName(value);
        break;
      case "city":
        if (value === props.data.city) {
          setIsChangeCity(false);
        } else {
          setIsChangeCity(true);
        }
        setCity(value);
        break;
      case "state":
        if (value === props.data.state) {
          setIsChangeState(false);
        } else {
          setIsChangeState(true);
        }
        setState(value);
        break;
      case "country":
        if (value === props.data.country) {
          setIsChangeCountry(false);
        } else {
          setIsChangeCountry(true);
        }
        setCountry(value);
        break;
      default:
        break;
    }
  };

  const onBlur = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value.trim();

    switch (e.target.name) {
      case "name":
        const isChangeName = value !== "" && value !== props.data.name;
        setName(value);
        setIsChangeName(isChangeName);
        break;
      case "last_name":
        const isChangeLastName = value !== "" && value !== props.data.last_name;
        setLastName(value);
        setIsChangeLastName(isChangeLastName);
        break;
      case "community":
        const isChangeCommunity =
          value !== "" && value !== props.data.community;
        setCommunity(value);
        setIsChangeCommunity(isChangeCommunity);
        break;
      case "position":
        const isChangePosition = value !== "" && value !== props.data.position;
        setPosition(value);
        setIsChangePosition(isChangePosition);
        break;
      case "accountID":
        const isChangeAccountID =
          value !== "" && value !== props.data.accountID;
        setIsChangePosition(isChangeAccountID);
        setAccountID(value);
        break;
      case "prefix":
        const isChangePrefix = value !== "" && value !== props.data.accountID;
        setIsChangePrefix(isChangePrefix);
        setPrefix(value);
        break;
      case "preferredName":
        const isChangePreferredName =
          value !== "" && value !== props.data.preferredName;
        setIsChangePreferredName(isChangePreferredName);
        setPreferredName(value);
        break;
      case "city":
        const isChangCity = value !== "" && value !== props.data.city;
        setIsChangeCity(isChangCity);
        setCity(value);
        break;
      case "state":
        const isChangeState = value !== "" && value !== props.data.state;
        setIsChangeState(isChangeState);
        setState(value);
        break;
      default:
        break;
    }
  };

  // Send content information to API.
  const onSubmitUser = async () => {
    setHasSentData(true);

    const name2 = name ? name : props.data.name;
    const last_name2 = lastName ? lastName : props.data.last_name;
    const community2 = isChangeCommunity ? community : props.data.community;
    const position2 = isChangePosition ? position : props.data.position;
    const accountID2 = isChangeAccountID ? accountID : props.data.accountID;
    const prefix2 = isChangePrefix ? prefix : props.data.prefix;
    const preferredName2 = isChangePreferredName
      ? preferredName
      : props.data.preferredName;
    const city2 = isChangeCity ? city : props.data.city;
    const state2 = isChangeState ? state : props.data.state;
    const country2 = isChangeCountry ? country : props.data.country;
    const role2 = isChangeRole ? role : props.data.role.id;

    const info = {
      user: {
        name: name2,
        last_name: last_name2,
        community: community2,
        position: position2,
        accountID: accountID2,
        prefix: prefix2,
        preferredName: preferredName2,
        city: city2,
        state: state2,
        country: country2,
        role: role2,
      },
    };

    const action_type = "UPDATE";
    const description =
      action_type + " USER: " + props.data.name + " " + props.data.last_name;
    const module = "USERS";

    const logInfo = {
      action_type,
      description,
      module,
      user: user.id,
    };

    try {
      // Create log
      await logsAPI.createLog(logInfo);

      const { data } = await usersAPI.updateUser(props.data.id, info);

      toast(<MsgNotification msg="User has been updated!" />);
      resetState();
      setHasSentData(false);
      props.closeModal(data);
    } catch (error) {
      /* const {data} = error.response;

				if(data.cause)
						if(data.cause.name === "TokenExpiredError")
							localStorage.setItem('sessionExpired','true'); */
      setHasSentData(false);
      setError(true);
      setErrorMessage("Sorry! An error ocurred while processing your request.");
    }
  };

  const onHideModal = () => {
    resetState();
    props.closeModal();
  };

  const resetState = () => {
    setCommunity("");
    setPosition("");
    setError(false);
    setErrorMessage("");
  };

  const disabledButton = () =>
    (!isChangeName &&
      !isChangeLastName &&
      !isChangeCommunity &&
      !isChangePosition &&
      !isChangeAccountID &&
      !isChangePrefix &&
      !isChangePreferredName &&
      !isChangeCity &&
      !isChangeState &&
      !isChangeCountry &&
      !isChangeRole) ||
    error;

  const disabled = disabledButton();
  name = isChangeName ? name : props.data.name;
  lastName = isChangeLastName ? lastName : props.data.last_name;
  community = isChangeCommunity ? community : props.data.community;
  position = isChangePosition ? position : props.data.position;
  accountID = isChangeAccountID ? accountID : props.data.accountID;
  prefix = isChangePrefix ? prefix : props.data.prefix;
  preferredName = isChangePreferredName
    ? preferredName
    : props.data.preferredName;
  city = isChangeCity ? city : props.data.city;
  state = isChangeState ? state : props.data.state;
  country = isChangeCountry ? country : props.data.country;
  role = isChangeRole ? role : props.data.role.id;

  return (
    <>
      <Modal show={props.showModal} onHide={onHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {error && (
              <Col lg={6}>
                <Alert
                  variant="danger"
                  onClose={() => {
                    setError(false);
                    setErrorMessage("");
                  }}
                  dismissible
                >
                  {errorMessage}
                </Alert>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={name}
                  placeholder="Type a name..."
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="last_name"
                  value={lastName}
                  placeholder="Type a last name..."
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group>
                <Form.Label>Community</Form.Label>
                <Form.Control
                  type="text"
                  name="community"
                  value={community}
                  placeholder="Type a community..."
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Label>Position</Form.Label>
                <Form.Control
                  type="text"
                  name="position"
                  value={position}
                  placeholder="Type a position..."
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Label>Account ID</Form.Label>
                <Form.Control
                  type="text"
                  name="accountID"
                  value={accountID}
                  placeholder="Type an account ID..."
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group>
                <Form.Label>Prefix</Form.Label>
                <Form.Control
                  type="text"
                  name="prefix"
                  value={prefix}
                  placeholder="Type a prefix..."
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Label>Preferred Name</Form.Label>
                <Form.Control
                  type="text"
                  name="preferredName"
                  value={preferredName}
                  placeholder="Type a preferred name..."
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={city}
                  placeholder="Type a city..."
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  value={state}
                  placeholder="Type a state..."
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Select
                  name="country"
                  placeholder="select"
                  aria-label="country"
                  value={country}
                  onChange={onChange}
                >
                  <option value="">Select a country...</option>
                  {Object.entries(countries).map(([key, value]) => (
                    <option value={value} key={key}>
                      {value}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Select
                  name="role"
                  placeholder="select"
                  aria-label="role"
                  value={role}
                  onChange={onChange}
                >
                  {roles.map((d) => (
                    <option value={d.id} key={d.id}>
                      {d.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <hr />
            <Col lg={12}>
              <Button
                className="pull-right"
                variant="success"
                onClick={onSubmitUser}
                disabled={disabled}
              >
                {hasSentData ? <SpinnerLoading /> : "Save"}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditUser;
