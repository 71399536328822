import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import socket from "@/config/sockets";
import { notificationsAPI, activitiesAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import { getFileIcon, orderByDescending } from "@/helpers/MainFunctions";
import { MsgWelcome, TitleH1, TitleH2, BtnPointEllipsis, Quotes, OpenQuotesImg, ClosedQuotesImg } from "@/styles/Template";
import HigligthImgBk from "@/assets/img/var/higligth-bk.png";
import OpenQuotes from "@/assets/img/var/comillas_icono1.png";
import ClosedQuotes from "@/assets/img/var/comillas_cerradas1.png";

const Activity = () => {

  const user = useContext(UserContext);
  let [notifications,setNotifications] = useState([]);
  let [activities,setActivities] = useState([]);
  const [width,setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const getInformation = async () => {
      try {
        const notifications = await notificationsAPI.getNotifications(user.id, 5);
        const dataActivities = await activitiesAPI.highlightsActivity(user.id);

        setNotifications(notifications.data.response);
        setActivities(dataActivities.data.response);
      } catch (error) {
        console.log(error);
      }
    }

    getInformation();

    socket().socket.on('notifications', function(data) {
      switch (data.type) {
        case "create":
          // If this notification is for the logged in user then add this notification to notifications state 
          if (data.info.user === user.id) {
            setNotifications(prevState => [...prevState, data.info]);
          }
        break;

        case "destroy":
          // If this notification is a content notification
          if (data.info.role) {
            //If this notification is for the role of the logged in user then remove this notification from notifications state
            if (data.info.role === user.role.id) {
              setNotifications(prevState => prevState.filter(d => d.notification.id !== data.info.id));
            }
          }
          else {
            // If this notification is a newsletter notification
            setNotifications(prevState => prevState.filter(d => d.notification.id !== data.info.id));
          }
        break;

        default:
        break;
      }
    });

    socket().socket.on('activities', function(data) {
      switch (data.type) {
        case "create":
          // If this activity is for the logged in user then add this activity to activities state
          if (data.info.user === user.id) {
            setActivities(prevState => {
              //Check if it is a different link.
              const result = prevState.some(d => d.id === data.info.link);
              if(result) {
                return prevState;
              }
              return [...prevState, data.info];
            });
          }
        break;

        case "destroy":
          // If this activity is for the logged in user then remove this activity from activities state
          if (data.info.user === user.id) {
            setActivities(prevState => prevState.filter(d => d.folderId !== data.info.folderId));
          }
          break;

        default:
        break;
      }
    });
  },[user]);

  useEffect(() => {
    const updateDimensions = () => setWidth(window.innerWidth)
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  });

  const handleNotification = async (id, seen) => {
    if (!seen) {
      let data = {
        notification: {
          seen: true
        }
      };

      try {
        await notificationsAPI.updateNotification(id, data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  notifications = orderByDescending(notifications);
  activities = orderByDescending(activities);

  return (
    <div className="row">
      <div className="col-12 col-lg-8">
        <MsgWelcome width="100%" cellPadding="0" cellSpacing="0" className="border-all mt-initial-table">
          <tbody>
            <tr>
              <td>
                <TitleH1 className="text-center">
                  Welcome to the Tergar Community Leadership and Training Portal
                </TitleH1>
                <p>
                  This community portal contains information and resources needed to manage your Tergar Practice Group and Tergar Meditation Community.
                </p>
              </td>
            </tr>
          </tbody>
        </MsgWelcome>

        {width <= 767 && (
          <Quotes>
            <OpenQuotesImg src={OpenQuotes}></OpenQuotesImg>
            <p>The community itself is yours. It is there to support you on the path of awakening, and it will be your commitment and support that will allow for the flourishing of the community in years to come. Receiving support and guidance from the community, and giving back in whatever way we can, is an integral part of the journey.</p>
            <ClosedQuotesImg src={ClosedQuotes}></ClosedQuotesImg>
            <span className="name">Yongey Mingyur Rinpoche</span>
          </Quotes>
        )}

        {
          width >= 768 && width <= 991 && (
            <Quotes>
              <OpenQuotesImg src={OpenQuotes}></OpenQuotesImg>
              <p>The community itself is yours. It is there to support you on the path of awakening, and it will be your commitment and support that will allow for the flourishing of the community in years to come. Receiving support and guidance from the community, and giving back in whatever way we can, is an integral part of the journey.</p>
              <ClosedQuotesImg src={ClosedQuotes}></ClosedQuotesImg>
              <span className="name">Yongey Mingyur Rinpoche</span>
            </Quotes>
          )
        }

        <div data-tut="reactour__highlights">
          <TitleH2>Highlights</TitleH2>
          <table
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            className="border-outside"
          >
            <tbody>
              {activities.length > 0 ? (
                activities.map((d) => (
                  <tr className="archive" key={JSON.stringify(d)}>
                    <td
                      className={
                        "description-file " + getFileIcon(d.fileName)
                      }
                    >
                      <Link to={{ pathname: `${d.id}` }}>{d.fileName}</Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="archive no-border-xs">
                  <td className="info-clear-notification">
                    <p className="legend-notification">
                      Most frequently viewed files.
                    </p>
                    <img
                      alt="Highlights"
                      src={HigligthImgBk}
                      className="img-fluid center-block"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/************* Mobile version *************/}
        {width <= 767 && (
          <>
            <TitleH2>Notifications</TitleH2>
            <table
              width="100%"
              cellPadding="0"
              cellSpacing="0"
              className="border-outside"
            >
              <tbody>
                {notifications.length > 0 ? (
                  <>
                    {notifications.map((d, k) => {
                      return (
                        <tr className="archive" key={k}>
                          <td className="description-notification" onClick={() => handleNotification(d.id, d.seen)}>
                            <a href={process.env.BASE_URL + d.notification.link}>
                              {d.notification.description}
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                    <tr className="text-center">
                      <td>
                        <a href=" ">
                          <span className="glyphicon glyphicon-option-horizontal" aria-hidden="true"></span>
                        </a>
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr className="archive">
                    <td className="info-clear-notification">
                      <p className="legend-notification">
                        Recent notifications are shown here.
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
      </div>

      <div className="col-12 col-lg-4 d-none d-md-block">
        <table
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          className="border-outside mt-initial-table"
        >
          <tbody>
            <tr className="archive">
              <td className="profile-dashboard">
                <span>{user.name + " " + user.last_name}</span>
                <span>{user.email}</span>
                <span>
                  {user.role.name} / {user.community}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <Quotes>
          <OpenQuotesImg src={OpenQuotes}></OpenQuotesImg>
          <p>The community itself is yours. It is there to support you on the path of awakening, and it will be your commitment and support that will allow for the flourishing of the community in years to come. Receiving support and guidance from the community, and giving back in whatever way we can, is an integral part of the journey.</p>
          <ClosedQuotesImg src={ClosedQuotes}></ClosedQuotesImg>
          <span className="name">Yongey Mingyur Rinpoche</span>
        </Quotes>

        <div data-tut="reactour__notifications">
          <TitleH2>Notifications</TitleH2>
          <table
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            className="border-outside"
          >
            <tbody>
              {notifications.length > 0 ? (
                <>
                  {notifications.map((d, k) => {
                    return (
                      <tr className="archive" key={k}>
                        <td className="description-notification" onClick={() => handleNotification(d.id, d.seen)}>
                          <a href={process.env.BASE_URL + d.notification.link}>
                            {d.notification.description}
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                  <tr className="text-center borde-wht">
                    <td>
                      <BtnPointEllipsis href=" ">
                        <span className="glyphicon glyphicon-option-horizontal" aria-hidden="true"></span>
                      </BtnPointEllipsis>
                    </td>
                  </tr>
                </>
              ) : (
                <tr className="archive">
                  <td className="info-clear-notification">
                    <p className="legend-notification">
                      Recent notifications are shown here.
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Activity;