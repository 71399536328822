import socketIOClient from "socket.io-client";
import sailsIOClient from "sails.io.js";

const socket = () => {
  let io = socketIOClient.sails ? socketIOClient : sailsIOClient(socketIOClient);

  io.sails.url = process.env.REACT_APP_API_URL;
  io.sails.reconnection = true;
  io.sails.environment = process.env.NODE_ENV;
  io.sails.path = `${process.env.REACT_APP_SOCKET_URL}/socket.io`;

  return io;
};

export default socket;