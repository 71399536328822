import { Fragment } from "react";
import { notificationsAPI } from "@/libs/api";
import { orderByDescending } from "@/helpers/MainFunctions";
import { Link } from "react-router-dom";

const Notification = ({notifications, setNotifications}) => {

  const handleNotification = async id => {
    let data = {
      notification: {
        seen: true
      }
    };

    try {
      await notificationsAPI.updateNotification(id, data);
      setNotifications([...notifications.filter(d => d.id !== id)]);
    } catch (error) {
      console.error(error);
    }
  };

  return(
    <li className='nav-item dropdown notification-dropdown' data-tut="reactour__notificationsbell">
      <a href="#no-data" className="nav-link dropdown-toggle notification-menu" data-bs-toggle="dropdown" role="button" aria-expanded="false">
        &nbsp;{" "}
        {notifications.length > 0 && (
          <span>{notifications.length}</span>
        )}
      </a>
      <ul className="dropdown-menu position-absolute" role="menu">
        {notifications.length > 0 ? 
          orderByDescending(notifications).map((d, k) => (
            <Fragment key={k}>
              <li className="dropdown-item" onClick={() => handleNotification(d.id)}>
                <Link to={process.env.PUBLIC_URL + d.notification.link}>
                  {d.notification.description}
                </Link>
              </li>
              {notifications.length - 1 !== k && (
                <li className="dropdown-divider"></li>
              )}
            </Fragment>
          )) : (
          <li className="info-clear-notification">
            You don't have notifications
          </li>
          )}
      </ul>
    </li>
  );
}

export default Notification;