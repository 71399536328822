import { Element } from "react-scroll";
import { SectionBlock, TitleSection, InfoSpecs } from "@/layouts/Landing";

const AboutUs = () => 
    <Element name="aboutus">
      <SectionBlock>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-10 col-md-8 offset-0 offset-sm-1 offset-md-2">
              <TitleSection>
              The Portal for Tergar's Community Leadership
              </TitleSection>
            </div>
            <InfoSpecs className="col-12 col-md-4">
              <h3 className="specs01">
              <br />
              Simple and Easy
              </h3>
              <p>
              Your personal dashboard organizes files and folders so you
              can find what you need, when you need it, on your favorite
              device.
              </p>
            </InfoSpecs>
            <InfoSpecs className="col-12 col-md-4">
              <h3 className="specs02">
              <br className="visible-xs" />
              Get All Your Files at <br className="hidden-xs" /> Your
              Fingertips
              </h3>
              <p>
              Community leadership resources are categorized by roles. You
              can access your files at any time.
              </p>
            </InfoSpecs>
            <InfoSpecs className="col-12 col-md-4">
              <h3 className="specs03">
              <br /> Stay Up to Date
              </h3>
              <p>
              Automatically receive notifications whenever community
              leadership materials are updated.
              </p>
            </InfoSpecs>
          </div>
        </div>
      </SectionBlock>
    </Element>

export default AboutUs;