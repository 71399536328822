import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { ActiveRoute } from "@/helpers/RouterFunctions";

const MenuMobile = forwardRef(({ dataFolders, isActiveToggle, setActiveToggle }, ref) => {
  return (
    <div ref={ref} className={isActiveToggle ? 'collapse show' : 'collapse'} id="navbarMobile">
      <ul className="nav navbar-nav navbar-right d-block d-md-none">
        <li className={ActiveRoute("/dashboard/")} onClick={() => setActiveToggle(false)}>
          <Link to="/dashboard/"> Home </Link>
        </li>
        <li className={ActiveRoute("/dashboard/newsletters")} onClick={() => setActiveToggle(false)}>
          <Link to="/dashboard/newsletters">Newsletters</Link>
        </li>
        <li className="dropdown">
          <a
            id="dropdownDocuments"
            href="#no-data"
            className="dropdown-toggle"
            data-bs-toggle="dropdown"
            role="button"
            aria-expanded="false"
          >
            My Documents <span className="caret"></span>
          </a>
          <ul className="dropdown-menu" aria-labelledby="dropdownDocuments">
            {dataFolders.map(d => (
              <li key={d.folderPath}>
                <Link
                  className="dropdown-item"
                  to={{
                    pathname: `/dashboard/folders/${d.folderPath}`,
                    state: {
                      description: d.description,
                      title: d.title
                    }
                  }}
                  onClick={() => setActiveToggle(false)}
                >
                  {d.title}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        <li className="dropdown">
          <a
            id="dropdownTraining"
            href="#no-data"
            className="dropdown-toggle"
            data-bs-toggle="dropdown"
            role="button"
            aria-expanded="false"
          >
            Training <span className="caret"></span>
          </a>
          <ul className="dropdown-menu" aria-labelledby="dropdownTraining">
            <li onClick={() => setActiveToggle(false)}>
              <Link className="dropdown-item" to="/dashboard/training">Online Leadership Training Module</Link>
            </li>
          </ul>
        </li>
        <li className="disabled">
          <a href="#no-data">Calendar</a>
        </li>
      </ul>
    </div>
  )
});

export default MenuMobile;