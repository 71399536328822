import { useEffect, useState } from 'react';
import { GlobalStyle } from '@/layouts/Landing';
import Header from './sections/Header';
import Home from './sections/Home';
import AboutUs from './sections/AboutUs';
import Porpuse from './sections/Porpuse';
import ContactUs from './sections/ContactUs';
import { logout } from '@/helpers/SessionFunctions';
import { useAuth0 } from '@auth0/auth0-react';

const Landing = () => {
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0();
  const [idToken, setIdToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    //Method to load access token and id token
    async function loadIdToken() {
      if (isAuthenticated) {
        const token = await getIdTokenClaims();
        const accessTokenSilently = await getAccessTokenSilently();

        setAccessToken(accessTokenSilently);
        setIdToken(token.__raw);
      }
    }

    loadIdToken().then(() => {
      if (localStorage.getItem('errorMsg') !== null) {
        const error = JSON.parse(localStorage.getItem('errorMsg'));

        if (error.code === 'LOGIN_ERROR') alert(error.message);

        if (error.code === 'ACCESS_DENIED') {
          alert(
            'You don’t have permission to access this site, please contact a Tergar Administrator.'
          );
          logout(true);
        }
      }
    });

    //if (!hasSession()) {
    //  logout(true);
    //}
  }, [isAuthenticated, idToken, accessToken]);

  return (
    <>
      <GlobalStyle />
      {/* <StatusBarNews>
          <div className="visible-md visible-lg visible-xl">
            <span className="bi bi-exclamation-octagon" aria-hidden="true">&nbsp;</span>
            Dear users, our website will be down for maintenance on <strong>Wednesday, November 22st from 10:00 a.m. to 10:00 p.m. US CST</strong>
          </div>
        </StatusBarNews> */}
      {/*-*******************************************************  MENU PAG  *******************************************************-*/}
      <Header />
      {/*-********************************************************  BANNER  ********************************************************-*/}
      <Home />
      {/*-********************************************************  SECTION  *******************************************************-*/}
      <AboutUs />
      {/*-********************************************************  SECTION  *******************************************************-*/}
      <Porpuse />
      {/*-********************************************************  SECTION  *******************************************************-*/}
      <ContactUs />
    </>
  );
};

export default Landing;
