import { useContext, useEffect, useState } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MsgNotification } from "@/config/toast";
import { contentsAPI, rolesAPI, foldersAPI, logsAPI } from '@/libs/api';
import { UserContext } from '@/libs/context/User';
import { SpinnerLoading } from '@/styles/Template';

const AddContent = (props) => {
  const user = useContext(UserContext);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [folderPath, setFolderPath] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [folders, setFolders] = useState(null);
  const [totalCharacters, setTotalCharacters] = useState(0);
  const [maxCharacters] = useState(250);
  const [hasSentData, setHasSentData] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const {data: {response: roles}} = await rolesAPI.getRoles();
        const {data: {response: folders}} = await foldersAPI.getFolderItems(process.env.REACT_APP_ROOT_FOLDER_ID);
        const filteredFolders = folders.entries.filter(d => d.type === "folder");
        setRoles(roles);
        setFolders(filteredFolders);
      } catch (error) {
        console.log(error);
      }
    }

    getData();
  }, []);

  const onChangeTitle = (e) => {
    const title = e.target.value;
    setTitle( title );
  }

  const onChangeDescription = (e) => {
    let description = e.target.value;
    let totalCharacters = description.length;

    if (totalCharacters > maxCharacters) {
        description = description.substring(0, maxCharacters);
        totalCharacters = description.length;
    }

    setDescription(description);
    setTotalCharacters(totalCharacters);
  }

  const onChangeFolderPath = e => {
    const folderPath = e.target.value;
    setFolderPath(folderPath);
  }

  const onBlurDescription = e => {
    const description = e.target.value.trim();
    const totalCharacters = description.length;
    setDescription(description);
    setTotalCharacters(totalCharacters);
  };

  // Send content information to API.
  const onSubmitContent = async () => {
    try {
      setHasSentData(true);

      const info = {
        title,
        description,
        folderPath,
        roles: selectedRoles
      }

      const action_type = 'CREATE';
      const log_description = action_type + ' CONTENT: ' + info.title;
      const module = 'CONTENTS';

      const logInfo = {
        action_type,
        description: log_description,
        module,
        user: user.id
      };
      const {data: {response: createdContent} } = await contentsAPI.createContent(info);

      // Create log
      await logsAPI.createLog(logInfo);

      toast(<MsgNotification msg='Content has been created!'/>);

      resetState();
      setHasSentData(false);
      props.closeModal(createdContent);
    }
    catch (error) {
      setHasSentData(false);
      setError(true);
      setErrorMessage('Sorry! An error ocurred while processing your request.');
    }
  }

  const onHideModal = () => {
    resetState();
    props.closeModal();
  }

  const resetState = () => {
    setTitle('');
    setDescription('');
    setFolderPath('');
    setSelectedRoles([]);
    setError(false);
  }

  const disabledButton = () =>
    title === '' || 
    description === '' || 
    folderPath === '' || 
    selectedRoles.length === 0 || 
    error;

  const handleCheckElement = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions);
    const selectedRoles =  selectedOptions.map(d => d.value);
    setSelectedRoles(selectedRoles);
  }

  const disabled = disabledButton();
  return (
    <>
      <Modal show={props.showModal} onHide={onHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {error &&
              <Col lg={12}>
                <Alert variant="danger" style={{position: "relative"}} onClose={() =>  { setError(false); setErrorMessage("");}} dismissible>
                  {errorMessage}
                </Alert>
              </Col>
            }
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" value={title} placeholder="Type a title..." onChange={onChangeTitle} />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Box Folder</Form.Label>
                <Form.Select placeholder="select" onChange={onChangeFolderPath}>
                    <option value="">Select a folder</option>
                    {folders !== null && folders.map((d) => <option value={d.id} key={d.id}>{d.name}</option>)}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" aria-label="textarea" rows="8" value={description} onChange={onChangeDescription} onBlur={onBlurDescription} />
                <span style={{ float: 'right' }}>{totalCharacters}/{maxCharacters}</span>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group controlId="formControlsSelectMultiple">
                <Form.Label>Roles</Form.Label>
                <br />
                <Form.Label>Select one o more roles.</Form.Label>
                <Form.Select multiple onChange={handleCheckElement}>
                    {roles.map(d => <option value={d.id} key={d.id}>{d.name}</option>)}
                </Form.Select>
              </Form.Group>
            </Col>
            <hr />
            <Col lg={12}>
              <Button className="pull-right" variant="success" onClick={onSubmitContent} disabled={disabled} >
                {hasSentData ? <SpinnerLoading /> :  "Save"}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddContent;