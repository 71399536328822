export const escapeRegExp = string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

export const validString = string => {
  const pattern = /[.*+?^${}()|[\]\\]/g;
  return pattern.test(string);
};

export const isEmailFormat = email => {
  const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  return regex.test(email);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const reverseString = string =>
  string.slice(((string.lastIndexOf(".") - 1) >>> 0) + 2);

export const getFileIcon = name => {
  switch (reverseString(name)) {
    case "gdoc":
    case "doc":
    case "docx":
    case "dotx":
    case "dot":
    case "boxnote":
    case "json":
      return "docfile";

    case "gsheet":
    case "xls":
    case "xlsx":
    case "xlsm":
    case "xlt":
      return "xlsfile";

    case "gslides":
    case "pptx":
    case "pptm":
    case "ppt":
    case "potx":
    case "potm":
    case "pot":
      return "pptfile";

    case "mp3":
    case "wav":
    case "pcm":
    case "acc":
    case "ogg":
    case "wma":
      return "audiofile";

    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
    case "svg":
      return "imgfile";

    case "pdf":
      return "pdffile";

    case "zip":
    case "rar":
      return "ziprarfile";

    default:
      return "unknownfile";
  }
};

export const orderByDescending = (data) => data.sort((a,b) => Number(b.createdAt) - Number(a.createdAt));

export const orderByAscending = (data) => data.sort((a,b) => Number(a.createdAt) - Number(b.createdAt));