import {Container, Row, Col } from "react-bootstrap";
import Card from "@Admin/components/Card";
import ListContact from "./listContact";

const Contacts = () =>
  <div className="content">
    <Container fluid>
      <Row>
        <Col md={12}>
          <Card title="Contacts" ctTableFullWidth ctTableResponsive>
            <ListContact />
          </Card>
        </Col>
      </Row>
    </Container>
  </div>

export default Contacts;