/**
 * Return user info.
 * @returns {json}
 */
export const getUserInfo = (isAuthenticated) => {
  let info;
  try {
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('ID_TOKEN');
    info =
      user !== null && token && isAuthenticated
        ? JSON.parse(user)
        : {
            error: true,
            message: "You don't have a session.",
            type: 'NO_SESSION'
          };
  } catch (error) {
    console.error(error);
    info = { error: true, message: error.message, type: error.name };
  }

  if (info.error) {
    console.error(info.message);
    logout(true, false);
  }

  return info;
};

/**
 * Check if session exists.
 * @returns {Boolean}
 */
export const hasSession = () =>
  getCookie('token') !== '' &&
  'user' in localStorage &&
  localStorage.getItem('user') !== 'null';

/**
 * Check if role is permitted.
 * @param {Array} allowedRoles - Available roles.
 * @param {string} role - Role name.
 * @returns {Boolean}
 */
export const hasRole = (allowedRoles = [], role) => allowedRoles.includes(role);

/**
 * Read cookie.
 * @param {string} cname - Cookie name.
 * @returns {string}
 */
export const getCookie = (cname) => {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

/**
 * Set cookie.
 * @param {string} cname - Cookie name.
 * @param {string} cvalue - Cookie value.
 * @param {int} exdays - Expiration day.
 */
export const setCookie = (cname, cvalue, exdays = 90, cookieDomain = null) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie =
    `${cname}=${cvalue}; ${expires};path=/` +
    (cookieDomain ? `; domain=${cookieDomain}` : '');
};

/**
 * Delete cookie.
 * @param {string} cname - Cookie name.
 * @param {string} domain - Domain for cookie.
 * @returns {string}
 */
export const deleteCookie = (cname, domain = null) =>
  (document.cookie = domain
    ? `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`
    : `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`);

export const logout = async (isLogout = false, redirect = true) => {
  if (isLogout) {
    const {
      REACT_APP_FORUMS_COOKIE,
      REACT_APP_FORUMS_COOKIE_DOMAIN,
      REACT_APP_AUTH0_COOKIE_DOMAIN
    } = process.env;
    const cookieDomain = REACT_APP_AUTH0_COOKIE_DOMAIN;

    deleteCookie(REACT_APP_FORUMS_COOKIE, REACT_APP_FORUMS_COOKIE_DOMAIN);
    deleteCookie('wp_user_a0Id', cookieDomain);

    localStorage.clear();

    //let logoutLink = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oidc/logout?id_token_hint=${tokenId}&post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT}`;
    if (redirect) {
      window.location.href = process.env.REACT_APP_REDIRECT + 'logout';
    }
  }
};
