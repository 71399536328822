import { useTour } from "@reactour/tour";
import { BtnGold } from "@/styles/Template";
import { openTutorial } from "@/helpers/TourFunctions";
import { logout } from "@/helpers/SessionFunctions";

const ProfileBox = ({ user }) => {
  const { setIsOpen, setSteps, setCurrentStep } = useTour();

  return (
    <li className="nav-item dropdown" data-tut="reactour__profile">
      <a href="#no-data" className="nav-link dropdown-toggle profile-user-menu d-flex align-items-center" data-bs-toggle="dropdown" role="button" aria-expanded="false">
        <span className="d-none d-md-block">
          {user ? user.name + " " + user.last_name : ""}
        </span>{" "}
        <span className="caret d-none d-md-block"></span>
      </a>
      <ul className="dropdown-menu position-absolute" role="menu">
        <li className="profile-display">
          <span className="dropdown-item">
            {user ? user.name + " " + user.last_name : ""}
          </span>
          <span className="dropdown-item">{user ? user.role.name : ""}</span>
          <span className="dropdown-item">{user ? user.email : ""}</span>
          {(window.location.pathname === `${process.env.PUBLIC_URL}/dashboard/` ||
            window.location.pathname === `${process.env.PUBLIC_URL}/dashboard`) && 
            (<span>
              <a href="#no-data" onClick={() => openTutorial(user, setIsOpen, setSteps, setCurrentStep)}>
                <span
                  className="bi bi-info-circle-fill"
                  aria-hidden="true"
                ></span>{" "}
                View tutorial
              </a>
            </span>)
          }
          <div className="dropdown-item">
            {user && user.role.name === "Administrator" && (
              <BtnGold href={process.env.PUBLIC_URL + "/admin/users"} target="__blank">
                Admin
              </BtnGold>
            )}

            <BtnGold onClick={() => logout(true)}>
              Log out
            </BtnGold>
          </div>
        </li>
      </ul>
    </li>
  )
}

export default ProfileBox;