import { forwardRef, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { filesAPI } from "@/libs/api";
import { SearchResult } from "@/styles/Template";

const SearchFiles = forwardRef(({isSearching, setSearching, dataFolders}, ref) => {
  const [valueSearched, setValueSearched] = useState('');
  const [searchedFiles, setSearchedFiles] = useState([]);
    
  const handleSearch = async e => {
    if (e.target.value.length < 3) {
      return;
    }

    // Get the files that correspond to query and boxFolders
    try {
      const folderPath = dataFolders.map(d => d.folderPath);
      const files = await filesAPI.searchFiles(e.target.value, folderPath.join());
      setSearchedFiles(files.data.response.entries);
    } catch (error) {
      console.error(error);
    }
  };

  const resetSearching = useCallback(() => {
    setSearchedFiles([]);
    setValueSearched("");
    setSearching(false);
  }, [setSearching]);

  return (
    <li className="search-nav">
      <div ref={ref}>
        <input
          type="text"
          placeholder="Search"
          value={valueSearched}
          onClick={() => setSearching(true)}
          onChange={(e) => setValueSearched(e.target.value)}
          onBlur={(e) => setValueSearched(e.target.value.trim())}
          onKeyUp={handleSearch}
          onFocus={() => {setSearchedFiles([]); setValueSearched("");}}
        />
        <button type="submit" onClick={() => setSearching(!isSearching)}>&nbsp;</button>
      </div>
        {searchedFiles.length > 0 &&
          <SearchResult>
            {searchedFiles.map(d => (
              <Link to={`/dashboard/files/preview/${d.id}`} key={JSON.stringify(d)} onClick={resetSearching}>
                {d.name}
              </Link>
            ))
            }
          </SearchResult>
        }
    </li>
  )
});

export default SearchFiles;