import { Element } from 'react-scroll';
import {
  TitleSection,
  SectionBlock,
  LinkSocial,
  LinkPie
} from '@/layouts/Landing';
import Social01 from '@/assets/img/icon/icon013.svg';
import Social03 from '@/assets/img/icon/icon015.svg';

const ContactUs = () => (
  <Element name="contactus">
    <SectionBlock>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-10 col-md-8 offset-0 offset-sm-1 offset-md-2">
            <TitleSection>Contact us</TitleSection>
            <p className="text-center">
              contact <b>communityteam@tergar.org</b>
            </p>
            <div className="text-center">
              <LinkSocial
                href="https://www.facebook.com/tergaronline/"
                target="_blank"
              >
                <img
                  src={Social01}
                  alt="facebook"
                />
                /Tergar
              </LinkSocial>
              <LinkSocial
                href="https://www.instagram.com/tergaronline/"
                target="_blank"
              >
                <img
                  src={Social03}
                  alt="instagram"
                />
                @Tergar
              </LinkSocial>
            </div>
            <p className="text-center">
              <LinkPie
                href="https://tergar.org/"
                target="_blank"
              >
                Tergar
              </LinkPie>
              &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
              <LinkPie
                href="https://www.iubenda.com/privacy-policy/53458676"
                target="_blank"
              >
                Privacy
              </LinkPie>
              &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
              <LinkPie
                href="https://app.tergar.org/terms-conditions-tergar-meditation.pdf"
                target="_blank"
              >
                T&Cs
              </LinkPie>
              &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
            <p className="text-center">
              Tergar International 1621 E Hennepin Avenue, Suite 210
              Minneapolis, MN 55414 USA ©Tergar International
            </p>
          </div>
        </div>
      </div>
    </SectionBlock>
  </Element>
);

export default ContactUs;
