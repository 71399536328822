import { useContext, useRef } from "react";
import "@/assets/css/pe-icon-7-stroke.css";
import { GlobalStyle } from "@/layouts/Admin";
import { hasSession } from "@/helpers/SessionFunctions";
import { AdminRoutes, adminRoutes as routes } from "@/routes/web";
import AdminNavbar from "./components/Navbar/AdminNavbar";
import Sidebar from "./components/Sidebar";
import { UserContext } from "@/libs/context/User";
// import Footer from "./components/Footer";

const Admin = (props) => {
  const user = useContext(UserContext);

  const mainPanel = useRef();

  if (user.role?.name !== 'Administrator' || !hasSession()){
    window.location = process.env.REACT_APP_REDIRECT;
    alert('You don\'t have permission this section');
    return null;
  }

  return (
    <>
      <GlobalStyle />
      <div className="wrapper">
        <Sidebar {...props} routes={routes} />

        <div id="main-panel" className="main-panel" ref={mainPanel}>
          <AdminNavbar {...props} />
          {AdminRoutes()}

          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
}

export default Admin;