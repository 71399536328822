import { useContext } from "react";
import { Nav } from "react-bootstrap";
import { UserContext } from "@/libs/context/User";

const AdminNavbarLinks = () => {
  const user = useContext(UserContext);

  return (
    <>
      <Nav>
        <Nav.Item href="">
          <Nav.Link>
            Welcome <b>{user.name + " " + user.last_name}</b>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
}

export default AdminNavbarLinks;