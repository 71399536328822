import { MenuSite, SystemLogo, BtnLoginGoto  } from "@/layouts/Landing";
import LogoTergar from "@/assets/img/var/logo-tergar.svg";
import { hasSession } from "@/helpers/SessionFunctions";
import LoginButton from "../components/Loginbutton";

const Header = () =>
  <MenuSite>
    <nav className="navbar navbar-default">
    <div className="container-fluid">
      {/*-- Brand and toggle get grouped for better mobile display -- */}
      <div className="navbar-header">
        <a href={process.env.PUBLIC_URL} className="navbar-brand">
          <SystemLogo src={LogoTergar} alt="Tergar" />
        </a>
      </div>
      {!hasSession() ? (
        <LoginButton label="Log in" />
        ) : (
        <BtnLoginGoto href={`${process.env.REACT_APP_REDIRECT}dashboard`}>
          Go to Community
        </BtnLoginGoto>
      )}
      </div>
      {/* -- /.container-fluid -- */}
    </nav>
  </MenuSite>

export default Header;