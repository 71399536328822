import { useContext, useState } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MsgNotification } from "@/config/toast";
import { contactsAPI, logsAPI } from '@/libs/api';
import { UserContext } from '@/libs/context/User';
import { isEmailFormat } from "@/helpers/MainFunctions";
import communities from "@/helpers/communities.js"
import { SpinnerLoading } from '@/styles/Template';

const AddContact = (props) => {
  const user = useContext(UserContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [position, setPosition] = useState('');
  const [description, setDescription] = useState('');
  const [community, setCommunity] = useState('');
  const [error, setError] =  useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasSentData, setHasSentData] = useState(false);

  const onChangeName = e => {
    const name = e.target.value;
    setName(name);
  }

  const onChangeEmail = e => {
    const email = e.target.value;
    setEmail(email);
  }
  
  const onBlurEmail = e => {
    const isValidEmail = isEmailFormat(e.target.value.trim());
    
    if(isValidEmail) {
      setError(false);
      setErrorMessage('');
    }else{
      setError(true);
      setErrorMessage('Email format is invalid.')
    }
  }

  const onChangePosition = e => {
    const position = e.target.value;
    setPosition(position);
  }

  const onChangeDescription = e => {
    let description = e.target.value;
    setDescription(description);
  }

  const onBlurDescription = e => {
    const description = e.target.value.trim();
    setDescription(description);
  }

  const onChangeCommunity = e => {
    const community = e.target.value;
    setCommunity(community);
  }

  const onSubmitContact = async () => {
    try {
      setHasSentData(true);
      const info = {
        name,
        email,
        position,
        description,
        community
      };

      const action_type = 'CREATE';
      const log_description = action_type + ' CONTACT: ' + info.name;
      const module = 'CONTACTS';

      const logInfo = {
        action_type,
        description: log_description,
        module,
        user: user.id
      };

      const {data: {response: createdContact}} = await contactsAPI.createContact(info);

      // Create log
      await logsAPI.createLog(logInfo);

      toast(<MsgNotification msg='Contact has been created!' />);

      resetState();
      setHasSentData(false);
      props.closeModal(createdContact);
    }catch (error) {
      setHasSentData(false);
      setError(true);
      setErrorMessage('Sorry! An error ocurred while processing your request.');
    }
  }

  const onHideModal = () => {
    resetState();
    props.closeModal();
  }

  const resetState = () => {
    setName('');
    setEmail('');
    setPosition('');
    setDescription('');
    setCommunity('');
    setError(false);
    setErrorMessage('');
  }

  const disabledButton = () => name === '' || email === '' || position === '' || description === '' || community === '' || error;

  const disabled = disabledButton();
  
  return(
    <Modal show={props.showModal} onHide={onHideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add contact</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <Row>
            {error &&
              <Col lg={12}>
                <Alert variant="danger" style={{position: "relative"}} onClose={() =>  { setError(false); setErrorMessage("");}} dismissible>
                  {errorMessage}
                </Alert>
              </Col>
            }
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" value={name} placeholder="Type a name" onChange={onChangeName} />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control type="text" value={email} placeholder="Type an email" onChange={onChangeEmail} onBlur={onBlurEmail}/>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Position</Form.Label>
                <Form.Control type="text" value={position} placeholder="Type a position" onChange={onChangePosition} />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Community</Form.Label>
                <Form.Select placeholder="select" onChange={onChangeCommunity}>
                  <option value="">Select a community</option>
                  {Object.entries(communities).map(
                    ([key, value]) => (<option value={value} key={key}>{value}</option>)
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" placeholder="Type a description" rows="8" value={description} onChange={onChangeDescription} onBlur={onBlurDescription} />
              </Form.Group>
            </Col>
            <hr />
            <Col lg={12}>
              <Button className="pull-right" variant="success" onClick={onSubmitContact} disabled={disabled}>
                {hasSentData ? <SpinnerLoading /> :  "Save"}
              </Button>
            </Col>
          </Row>
      </Modal.Body>
    </Modal>
  );
}

export default AddContact;