import { useContext, useState } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { newslettersAPI, logsAPI } from '@/libs/api';
import { MsgNotification } from "@/config/toast";
import { UserContext } from '@/libs/context/User';
import { SpinnerLoading } from '@/styles/Template';

const EditNewsletter = (props) => {

  const editorStyle = {
      border: '1px solid #F1F1F1',
      height: '150px',
  };

  const user = useContext(UserContext);
  let [title, setTitle] = useState("");
  let [description, setDescription] = useState(EditorState.createEmpty());
  let [newsletterLink, setNewsletterLink] = useState("");
  let [newsletterDate, setNewsletterDate] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isChangeTitle, setIsChangeTitle] = useState(false);
  const [isChangeDescription, setIsChangeDescription] = useState(false);
  const [isChangeNewsletterLink, setIsChangeNewsletterLink] = useState(false);
  const [isChangeNewsletterDate, setIsChangeNewsletterDate] = useState(false);
  const [hasSentData, setHasSentData] = useState(false);

  const onChangeTitle = (e) => {
    const title = e.target.value;
    setTitle(title);
    setIsChangeTitle(true);
  }

  const onChangeDescription = (description) => {
    setDescription(description)
    setIsChangeDescription(true);
  }

  const onChangeNewsletterLink = (e) => {
    const newsletterLink = e.target.value;
    setNewsletterLink(newsletterLink);
    setIsChangeNewsletterLink(true);
  }

  const onChangeNewsletterDate = (e) => {
    const newsletterDate = e.target.value;
    setNewsletterDate(newsletterDate);
    setIsChangeNewsletterDate(true);
  }

  // Send content information to API.
  const onSubmitNewsletter = async () => {
    try {
      setHasSentData(true);
      const title2 = isChangeTitle ? title : props.data.title;
      const description2 = isChangeDescription ? draftToHtml(convertToRaw(description.getCurrentContent())) : props.data.description;
      const newsletterLink2 = isChangeNewsletterLink ? newsletterLink : props.data.newsletterLink;
      const newsletterDate2 = isChangeNewsletterDate ? newsletterDate : props.data.newsletterDate;
  
      const info = {
        newsletter: {
          title: title2,
          description: description2,
          newsletterLink: newsletterLink2,
          newsletterDate: newsletterDate2
        }
      }
  
      const action_type = 'UPDATE';
      const log_description = action_type + ' NEWSLETTER: ' + info.newsletter.title;
      const module = 'NEWSLETTERS';
  
      const logInfo = {
        action_type,
        description: log_description,
        module,
        user: user.id
      };
      const {data: {response: updatedNewsletter}} = await newslettersAPI.updateNewsletter(props.data.id, info);

      // Create log
      await logsAPI.createLog(logInfo);

      toast(<MsgNotification msg='Newsletter has been updated!' />); 

      resetState();
      setHasSentData(false);
      props.closeModal(updatedNewsletter);
    }
    catch (error) {
      setHasSentData(false);
      setError(true);
      setErrorMessage("Sorry! An error ocurred while processing your request.");
    }
}

  const onHideModal = () => {
    resetState();
    props.closeModal();
  }

  const resetState = () => {
    setTitle("");
    setDescription(EditorState.createEmpty());
    setNewsletterLink("");
    setNewsletterDate("");
    setError(false);
  }

  const disabledButton = () => isChangeTitle &&  isChangeDescription &&  isChangeNewsletterLink &&  isChangeNewsletterDate &&  error;
  
  const onConvertHtmlToDraft = (description) => {
    const contentBlock = htmlToDraft(description);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);

      return editorState;
    }
    
    return '<p></p>';
  }

  const disabled = disabledButton();

  title = isChangeTitle ? title : props.data.title;
  description = isChangeDescription ? description : onConvertHtmlToDraft(props.data.description);
  newsletterLink = isChangeNewsletterLink ? newsletterLink : props.data.newsletterLink;
  newsletterDate = isChangeNewsletterDate ? newsletterDate : props.data.newsletterDate;

  return (
    <>
      <Modal show={props.showModal} onHide={onHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit newsletter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {error &&
              <Col lg={12}>
                <Alert variant="danger" onClose={() =>  { setError(false); setErrorMessage("");}} dismissible>
                  {errorMessage}
                </Alert>
              </Col>
            }
              <Col lg={12}>
                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control type="text" value={title} placeholder="Type a title..." onChange={onChangeTitle} />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Editor
                    ariaLabel='Newsletter description'
                    editorState={description}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    onEditorStateChange={onChangeDescription}
                    editorStyle={editorStyle}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Link</Form.Label>
                  <Form.Control type="text" value={newsletterLink} placeholder="Enter a link..." onChange={onChangeNewsletterLink} />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>Date</Form.Label>
                  <Form.Control type="date" value={newsletterDate} placeholder="Choose a date..." onChange={onChangeNewsletterDate} />
                </Form.Group>
              </Col>

              <hr />

              <Col lg={12}>
                <Button className="pull-right" variant="success" onClick={onSubmitNewsletter} disabled={disabled} >
                  {hasSentData ? <SpinnerLoading /> :  "Save"}
                </Button>
              </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditNewsletter;