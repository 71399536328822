import { useContext, useEffect, useState } from 'react';
import {Row, Col, Button, Table, OverlayTrigger, Tooltip} from "react-bootstrap";
import { toast } from "react-toastify";
import { MsgNotification } from "@/config/toast";
import { contactsAPI, logsAPI } from "@/libs/api";
import { UserContext } from '@/libs/context/User';
import { NoDataMessage, OverflowDescriptionAdmin } from "@/layouts/Admin";
import Search from "@Admin/components/Search";
import AddContact from "./addContact";
import EditContact from "./editContact";

const ListContact = () => {

  const user = useContext(UserContext);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  let [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editData, setEditData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const {data: {response: contacts} } = await contactsAPI.getContacts();
        setData(contacts);
      }
      catch(error) {
        console.log(error);
      }
    }

    getData();
  },[]);

  const handleShowAddModal = () => setShowAddModal(true);

  const handleShowEditModal = e => {
      const editData = data.filter(d => d.id === e.currentTarget.dataset.id);
      setShowEditModal(true);
      setEditData(editData);
  }

  const handleCloseAddModal = info => {
    if(info !== undefined){
      setShowAddModal(false); 
      setData(() => ([...data, info]));
    }else{
      setShowAddModal(false);
    }
  }

  const handleCloseEditModal = info => {
    if(info !== undefined) {
      const info2 = data.map(d => d.id === info.id ? info : d);
      
      setShowEditModal(false);
      setData([...info2]);
    }else{
      setShowEditModal(false);
    }
  }

  const removeContact = async id => {
    try {                    
      // Get contact info
      const contact = data.find(d => d.id === id);
      const action_type = 'DELETE';
      const description = action_type + ' CONTACT: ' + contact.name;
      const module = 'CONTACTS';

      const logInfo = {
        action_type,
        description,
        module,
        user: user.id
      };

      await contactsAPI.deleteContact(id);

      await logsAPI.createLog(logInfo);

      toast(<MsgNotification msg='Contact has been deleted!' />);
      const info = data.filter(d => d.id !== id);
      setData(info);
    }
    catch(error) {
      toast.error("Contact not deleted!");
    }
  }

  data = isSearching? filteredData : data;
  return (
    <>
    <Col lg={12}>
      <Row>
        <Col lg={6}>
          <Button variant="success" style={{ marginTop: "23px" }} onClick={handleShowAddModal} >
            <i className="pe-7s-plus"></i> Add new contact
          </Button>
        </Col>
        <Col lg={6} className="pull-right">
          <Search data={data} attribute="name" text="Type a contact name..." filteredData={(filteredData, isSearching) => {setFilteredData(filteredData); setIsSearching(isSearching);}} />
        </Col>
      </Row>
      {data.length > 0 ? 
        <Table striped bordered responsive hover style={{ marginTop: "23px" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Position</th>
              <th>Description</th>
              <th>Community</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {data.map(d => (
            <tr key={d.id}>
              <td>{d.name}</td>
              <td>{d.email}</td>
              <td>{d.position}</td>
                <OverflowDescriptionAdmin>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip">{d.description}</Tooltip>}>
                    <span className="description">{d.description}</span>
                  </OverlayTrigger>
                </OverflowDescriptionAdmin>
              <td>{d.community}</td>
              <td>
                  <Button variant="warning" onClick={handleShowEditModal} data-id={d.id}>
                    <i className="pe-7s-pen"></i> Edit
                  </Button>
                  <Button variant="danger" onClick={() => {
                    const r = window.confirm(
                      "Are you sure you want to delete this contact?"
                    );
                    if (r) removeContact(d.id);
                  }}
                  >
                    <i className="pe-7s-trash"></i> Delete
                  </Button>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      : isSearching ? (
        <NoDataMessage>
          No results found.
          <br />
          Try adjusting your search to find what you're looking
          for.
        </NoDataMessage>
      ) : (
        <NoDataMessage>
          It looks like you don't have any information yet.{" "}
          <br />
          Once you add information, this page will show it.{" "}
        </NoDataMessage>
      )}
      </Col>
      
      <AddContact
        showModal={showAddModal}
        closeModal={handleCloseAddModal}
      />

      {showEditModal && 
        (<EditContact
          data={editData[0]}
          allData={data}
          showModal={showEditModal}
          closeModal={handleCloseEditModal}
        />)
      }
    </>
  )
}

export default ListContact;