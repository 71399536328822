import styled, {createGlobalStyle} from "styled-components";
import BkBanner from '../assets/img/var/banner-landing.jpg';
import Specs1 from '../assets/img/icon/specs01.png';
import Specs2 from '../assets/img/icon/specs02.png';
import Specs3 from '../assets/img/icon/specs03.png';
import Relleno from '../assets/img/var/relleno2.jpg';

export const GlobalStyle = createGlobalStyle`
  html,body{font-weight: 400; color:#727070 !important; font-size: 14px !important; margin:0; height: 100%; position: relative;}
  .ml-btn{margin-left:10px;}
`;

export const  MenuSite = styled.div`
  a {
    text-decoration: none;
  }

  .navbar {
    border-radius:0;
    border: none;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
    font-family: 'Open Sans', sans-serif !important;
    font-size: 13px;
    margin: 0;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .navbar-brand {
    height: 62px;
    padding: 14px 15px;
    display: block;

    @media  (max-width: 767px){
      height:50px;
      padding: 10px 15px
    }
  }

  .navbar-default{
    background: rgba(255,255,255,0.9);
  }

  .navbar-right{
    margin-top: 14px;
  }

  .navbar-nav > li {
    margin: 0 12px;
  }

  .navbar-nav > li:last-child {
    margin: 0;

    @media  (max-width: 767px) {
      margin: 0 12px;
    }
  }

  .navbar-nav > li > a {
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-top: 6px;
    padding-bottom: 6px;
    transition: all 0.3s ease;
  }

  .navbar-nav > li:last-child > a {
    color:#e39f24;
    border: 1px solid #e39f24;
    border-radius:4px;
    margin-left: 28px;

    @media  (max-width: 767px) {
      margin-left: 0;
      max-width: 84px;
    }

    &:hover {
      background-color:#e39f24;
      color:#fff;
      text-shadow: 1px 1px rgba(0,0,0,0.25);
    }
  }
`;

export const  BtnLoginGoto = styled.a`
  float: right;
  color: #e39f24;
  border: 1px solid #e39f24;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 6px 14px;
  transition: all 0.3s ease;

  &:hover{
    background-color:#e39f24;
    color:#fff;
    text-shadow: 1px 1px rgba(0,0,0,0.25);
    text-decoration: none;
  }

  @media  (max-width: 767px){
    position: absolute;
    top: 0;
    margin: 9px 0 0;
    right: 15px;
  }
`;

export const  BannerLanding = styled.div`
  text-align:center;
  background:url(${BkBanner}) 60% center no-repeat;
  background-size:cover;
  padding: 40px 0 0;
  font-family: 'Open Sans', sans-serif !important;
  text-shadow: 1px 1px 0 #fff;
  margin-bottom: 60px;

  @media (max-width: 767px){
    background-position: 0 center;
  }

  h1 {
    position: relative;
    padding:0 18px 20px;
    font-size: 34px;
    font-weight: 300;
    margin-bottom:20px;
  }

  p {
    padding: 0 20px;
    font-size:16px;
  }

  h1::before{
    content:'';
    position:absolute;
    bottom:0;
    left:50%;
    margin-left: -40px;
    width: 60px;
    height: 4px;
    background:#e39f24;        
    box-shadow:1px 1px 0 #fff;
  }
`;



export const  TitleSection = styled.h2`
  position: relative;
  padding-bottom:20px;
  font-size: 30px;
  font-weight: 300;
  margin-bottom:20px;
  font-family: 'Open Sans', sans-serif !important;
  text-align: center;
    
  &:before{
    content:'';
    position:absolute;
    bottom:0;
    left:50%;
    margin-left: -30px;
    width: 60px;
    height: 4px;
    background:#e39f24;        
    box-shadow:1px 1px 0 #fff;
  }
`;



export const  SectionBlock = styled.section`
  margin-bottom: 60px;
  font-family: 'Open Sans', sans-serif !important;
`;



export const SectionGray = styled.div`
  background-color:#f4f6f6;
  text-shadow: 1px 1px #fff;
  padding: 40px 0;
`;

export const ImgRelleno = styled.div`
  background: url(${Relleno}) center/cover;
  padding: 160px 0;
`;

export const  InfoSpecs = styled.div`
  margin-top:20px;
  font-family: 'Open Sans', sans-serif !important;

  h3 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400;
    padding: 4px 0 4px 46px;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 36px;
    font-size:18px;
  }

  .specs01{
    background-image:url(${Specs1});
  }

  .specs02{
    background-image:url(${Specs2});
  }

  .specs03{
    background-image:url(${Specs3});
  }
`;

export const  RolUser = styled.div`
  text-align:center;
  font-family: 'Open Sans', sans-serif !important;

  img {
    background-color:#e39f24;
    margin: 0 auto 10px;
    border-radius: 44px;
    width: 80px;
  }
`;


export const  LinkSocial = styled.a`
  text-align:center;
  display:inline-block;
  width: 70px;
  margin: 26px 26px 80px;
  text-transform: uppercase;
  letter-spacing:1px;
  font-family: 'Open Sans', sans-serif !important;
  font-size:13px;
  color: #727070;
  transition: all 0.3s ease;
  text-decoration: none;

  @media  (max-width: 767px){
    width: 56px;
    margin: 26px 10px 80px;
    font-size:10.5px;
  }

  img{
    width: 90%;
    display: block;
    margin: 0 auto 10px;
  }

  &:hover{
    color:#727070;
    opacity: .8;
    text-decoration:none;
  }
`;


export const  LinkPie = styled.a`
  color:#727070;
  text-decoration: none;
  display: inline-block;

  &:hover{
    color:#727070;
    text-decoration: none;
    opacity: .7;
  }
`;

export const SystemLogo = styled.img`
  height: 100%;
  width: auto;
`;

export const NewsletterWrapper = styled.div`
  .h4, .h5, .h6, h4, h5, h6 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  a {
    text-decoration: none;
  }
`
export const StatusBar = styled.div`
  overflow: hidden;
  padding: 6px 20px;
  position: fixed;
  right: 41px;
  left: 60px;
  z-index: 50;
  font-weight: 600;
  text-align:center;
  font-size: 14px;
  
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 99999999;
        -webkit-background-size: 50px 50px;
        -moz-background-size: 50px 50px;
        background-size: 50px 50px;
        overflow: hidden;   
    }
    
   @media  (max-width: 767px){
       right: 16px;
       left: 16px;
       padding: 5px;
       font-size: 12px;
       z-index: 50;
   }
`;

export const StatusBarNews = styled(StatusBar)`
  /*border-radius: 0px 0px 5px 5px;*/
  background-color: #FF6B6B;
  color: #fff;
  position: static;
  background-image: -moz-linear-gradient(top, #FF6B6B, #f42323);
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #FF6B6B),color-stop(1, #f42323));
  background-image: -webkit-linear-gradient(#FF6B6B, #f42323);
  top: 0;
  left: 0px;
  right: 0px;
  
    &:after {
        background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.25, rgba(255, 255, 255, .1)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255, 255, 255, .1)), color-stop(.75, rgba(255, 255, 255, .1)), color-stop(.75, transparent), to(transparent) );
        background-image: -moz-linear-gradient( -45deg, rgba(255, 255, 255, .1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .1) 50%, rgba(255, 255, 255, .1) 75%, transparent 75%, transparent ); 
    }
    .text-white-link{
      color: #fff;
      text-decoration: underline;
    }

    @media  (max-width: 767px){
      min-height: 45px;
    }

    @media  (max-width: 767px){
      z-index: 30;
    }
  display: flex;
  justify-content: center;
  align-items: center;  
`;