import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Row, Col, Button, Table, /* Pagination */ } from "react-bootstrap";
import { MsgNotification } from "@/config/toast";
import { logsAPI, usersAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import { NoDataMessage } from "@/layouts/Admin";
import Search from "@Admin/components/Search";
import EditUser from  "./editUser";

const ListUsers = () => {
  const user = useContext(UserContext);
  let [data, setData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [editData, setEditData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  data = isSearching? filteredData : data;

  useEffect(() => {
    const getData = async () => {
      const {data : { response: users}} = await usersAPI.getUsers();
      const activeUsers = users.filter(d => d.isActive === true);
      setData(activeUsers);
    }

    getData();
  },[]);

  const handleShowEditModal = e => {
    const editData = data.filter(d => d.id === e.currentTarget.dataset.id);
    setShowEditModal(true);
    setEditData(editData);
  };

  const handleCloseEditModal = info => {
    if (info !== undefined) {
      const info2 = data.map(d => d.id === info.id ? info : d);

      setShowEditModal(false);
      setData([...info2]);
    } else {
      setShowEditModal(false);
    }
  };

  const handleDisableUser = async id => {
    // Get user info
    const disabled_user = data.find(d => d.id === id);
    const action_type = 'DISABLE';
    const description = action_type + ' USER: ' + disabled_user.name + ' ' + disabled_user.last_name;
    const module = 'USERS';

    const logInfo = {
      action_type,
      description,
      module,
      user: user.id
    };
  
    try {
      // Create log
      await logsAPI.createLog(logInfo);
      
      const {data: {response: userData} } = await usersAPI.disableUser(id);

      toast(<MsgNotification msg="User has been disabled!" />);

      const info = data.filter(d => d.id !== userData.id);
      setData(info);
    } catch (error) {
      toast.error(error);
    }
  }

  return (
    <>
      <Col lg={12}>
        <Row>
          <Col lg={6}>
            <NavLink to="/admin/users/add" className="btn btn-success" style={{ marginTop: "23px" }}>
              <i className="pe-7s-plus"></i> Add new user
            </NavLink>
          </Col>
          <Col lg={6} className="pull-right">
            <Search
              data={data}
              text="Type an email, name, country, role or community"
              attribute="email,name,last_name,country,role,community"
              filteredData={(filteredData, isSearching) =>{ setFilteredData(filteredData); setSearching(isSearching);}}
            />
          </Col>
        </Row>
        {data.length > 0 ?
          <Table striped bordered responsive hover style={{ marginTop: "23px" }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>City/State</th>
                <th>Country</th>
                <th>Role</th>
                <th>Community</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map(d => (
                <tr key={d.id}>
                <td>{d.name + ' ' + d.last_name}</td>
                <td>{d.email}</td>
                <td>{d.city + ', ' + d.state}</td>
                <td>{d.country}</td>
                <td>{d.role.name}</td>
                <td>{d.community}</td>
                {<td>
                  {user.id !== d.id && 
                    <Button variant="warning"
                      onClick={handleShowEditModal}
                      data-id={d.id}>
                      <i className="pe-7s-pen"></i> Edit user
                    </Button>
                  }
                  {user.id !== d.id && <Button
                    variant="danger"
                    onClick={() => {
                      const r = window.confirm(
                      "Are you sure you want to delete this item?"
                      );
                      if (r) handleDisableUser(d.id);
                    }}
                    data-id={d.id}
                    >
                    <i className="pe-7s-trash"></i> Disable
                    </Button>}
                  </td>}
                  </tr>
              ))}
              </tbody>
          </Table>
        : isSearching ? (
          <NoDataMessage>
            No results found.
            <br />
            Try adjusting your search to find what you're looking
            for.
          </NoDataMessage>
          ) : (
          <NoDataMessage>
            It looks like you don't have any information yet.{" "}
            <br />
            Once you add information, this page will show it.{" "}
          </NoDataMessage>
        )}

    {/*<div className="text-center">
        <Pagination>
            <Pagination.First />
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Ellipsis />

            <Pagination.Item>{10}</Pagination.Item>
            <Pagination.Item>{11}</Pagination.Item>
            <Pagination.Item active>{12}</Pagination.Item>
            <Pagination.Item>{13}</Pagination.Item>
            <Pagination.Item disabled>{14}</Pagination.Item>

            <Pagination.Ellipsis />
            <Pagination.Item>{20}</Pagination.Item>
            <Pagination.Next />
            <Pagination.Last />
        </Pagination>
    </div>*/}
    </Col>
    {showEditModal && (
        <EditUser
          data={editData[0]}
          showModal={showEditModal}
          closeModal={handleCloseEditModal}
        />
    )}
    </>
  )
}

export default ListUsers;