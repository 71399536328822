import { useContext, useEffect, useState } from 'react';
import { Row, Col, Form, Button, Modal, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MsgNotification } from "@/config/toast";
import { contentsAPI, rolesAPI, foldersAPI, logsAPI } from '@/libs/api';
import { UserContext } from '@/libs/context/User';
import { SpinnerLoading } from '@/styles/Template';

const EditContent = (props) => {

  const user = useContext(UserContext);
  let [title, setTitle] = useState("");
  let [description, setDescription] = useState("");
  let [totalCharacters, setTotalCharacters] = useState(0);
  const [maxCharacters] = useState(250);
  let [folderPath, setFolderPath] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [folders, setFolders] = useState(null);
  const [isChangeTitle, setIsChangeTitle] = useState(false);
  const [isChangeDescription, setIsChangeDescription] = useState(false);
  const [isChangeFolderPath, setIsChangeFolderPath] = useState(false);
  const [isChangeRoles, setIsChangeRoles] = useState(false);
  const [hasSentData, setHasSentData] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const {data: {response: roles}} = await rolesAPI.getRoles();
        const selectedRoles = props.data.roles.map(d => d.id);
        const {data: {response: folders}} = await foldersAPI.getFolderItems(process.env.REACT_APP_ROOT_FOLDER_ID);
        const filteredFolders = folders.entries.filter(d => d.type === "folder");

        setRoles(roles);
        setSelectedRoles(selectedRoles);
        setFolders(filteredFolders);
      } catch (error) {
        console.log(error);
      }
    }

    getData();
  }, [props.data.roles]);

  const onChangeTitle = (e) => {
    const title = e.target.value;
    setTitle(title);
    setIsChangeTitle(true);
  }

  const onBlurTitle = (e) => {
    const title = e.target.value.trim();
    const isChangeTitle = title !== '' && title !== props.data.title;
    setTitle(title);
    setIsChangeTitle(isChangeTitle);
  }

  const onChangeDescription = (e) => {
    let description = e.target.value;
    let totalCharacters = description.length;

    if (totalCharacters > maxCharacters) {
      description = description.substring(0, maxCharacters);
      totalCharacters = description.length;
    }

    setDescription(description);
    setTotalCharacters(totalCharacters);
    setIsChangeDescription(true);
  }

  const onBlurDescription = e => {
    const description = e.target.value.trim();
    const isChangeDescription = description !== '' && description !== props.data.description;
    const totalCharacters = description.length;

    setDescription(description);
    setTotalCharacters(totalCharacters);
    setIsChangeDescription(isChangeDescription);
  };

  const onChangeFolderPath = (e) => {
    const folderPath = e.target.value;
    const isChangeFolderPath = folderPath !== props.data.folderPath && folderPath;

    setFolderPath(folderPath);
    setIsChangeFolderPath(isChangeFolderPath);
  }

  // Send content information to API.
  const onSubmitContent = async () => {
    try {
      setHasSentData(true);
      const title2 = isChangeTitle ? title : props.data.title;
      const description2 = isChangeDescription ? description : props.data.description;
      const folderPath2 = isChangeFolderPath ? folderPath : props.data.folderPath;
      
      const info = {
        data: {
          content: {
            title: title2,
            description: description2,
            folderPath: folderPath2,
          },
          roles: selectedRoles
        }
      }

      const action_type = 'UPDATE';
      const log_description = action_type + ' CONTENT: ' + info.data.content.title;
      const module = 'CONTENTS';

      const logInfo = {
        action_type,
        description: log_description,
        module,
        user: user.id
      };
      const {data: {response: updatedContent} } = await contentsAPI.updateContent(props.data.id, info);

      // Create log
      await logsAPI.createLog(logInfo);

      toast(<MsgNotification msg='Content has been updated!' />);

      resetState();
      setHasSentData(false);
      props.closeModal(updatedContent);
    }
    catch (error) {
      setHasSentData(false);
      setError(true);
      setErrorMessage("Sorry! An error ocurred while processing your request.");
    }
  }

  const onHideModal = () => {
    resetState();
    props.closeModal();
  }

  const resetState = () => {
    setTitle("");
    setDescription("");
    setFolderPath("");
    setRoles(roles.map(role => { role.isChecked = false; return role; }));
    setError(false);
    setErrorMessage("");
  }

  const disabledButton = () =>
    (!isChangeTitle 
    && !isChangeDescription 
    && !isChangeFolderPath 
    && !isChangeRoles)
    || error;

  const handleCheckElement = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions);
    let selectedRoles = selectedOptions.map(d => d.value);

    const isChangeRoles = selectedRoles.length > 0;
    setSelectedRoles(selectedRoles);
    setIsChangeRoles(isChangeRoles);
  }

  const disabled = disabledButton();

  title = isChangeTitle ? title : props.data.title;
  description = isChangeDescription ? description : props.data.description;
  folderPath = isChangeFolderPath ? folderPath : props.data.folderPath;
  totalCharacters = isChangeDescription ? totalCharacters : props.data.description.length;
  
  return (
    <Modal show={props.showModal} onHide={onHideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit content</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {error &&
            <Col lg={12}>
              <Alert variant="danger" onClose={() =>  { setError(false); setErrorMessage("");}} dismissible>
                {errorMessage}
              </Alert>
            </Col>
          }
          <Col lg={6}>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" value={title} placeholder="Type a title..." onChange={onChangeTitle} onBlur={onBlurTitle} />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group>
              <Form.Label>Box Folder</Form.Label>
              <Form.Select placeholder="select" onChange={onChangeFolderPath} value={folderPath}>
                <option value="">Select a folder</option>
                {folders !== null && folders.map((d) => <option value={d.id} key={d.id}>{d.name}</option>)}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={12}>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" aria-label="textarea" rows="8" value={description} onChange={onChangeDescription} onBlur={onBlurDescription} />
              <span style={{ float: 'right' }}>{totalCharacters}/{maxCharacters}</span>
            </Form.Group>
          </Col>
          <Col lg={12}>
            <Form.Group controlId="form.ControlsSelectMultiple">
              <Form.Label>Roles</Form.Label>
              <br />
              <Form.Label>Select one o more roles.</Form.Label>
              <Form.Select multiple value={selectedRoles} onChange={handleCheckElement}>
                {roles.map(d => <option value={d.id} key={d.id}>{d.name}</option>)}
              </Form.Select>
            </Form.Group>
          </Col>

          <hr />

          <Col lg={12}>
            <Button className="pull-right" variant="primary" onClick={onSubmitContent} disabled={disabled} >
              {hasSentData ? <SpinnerLoading /> :  "Save"}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default EditContent;