import { Form } from 'react-bootstrap';

const Search = (props) => {

  const onChangeSearch = (e) => {
    const word = e.target.value.toLowerCase();
    const attributes = props.attribute.split(',');
    const data = props.data.filter(d => attributes.some(field => typeof(d[field]) == 'object' ? d[field].name.toLowerCase().includes(word) : d[field].toLowerCase().includes(word)));

    const isSearching = word.length > 0;
    props.filteredData(data, isSearching);
  }

  return (
    <Form.Group >
      <Form.Label>Search: </Form.Label>
      <Form.Control type="text" placeholder={props.text} onChange={onChangeSearch} />
    </Form.Group>
  )
}

export default Search;