import http from "@/config/http";

/*************** <NOTIFICATIONS> *********************/
export const getNotifications = async (id, limit) => { 
  const query = limit ? `?limit=${limit}` : '';
    return await http.get(`v1/users/${id}/notifications${query}`)
};

export const updateNotification = async (id, data) => http.put(`v1/userNotifications/${id}`, data);

/*************** <NOTIFICATIONS/> *********************/