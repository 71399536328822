import { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Table, /* Pagination */ } from "react-bootstrap";
import { toast } from "react-toastify";
import { MsgNotification } from "@/config/toast";
import { contentsAPI, logsAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import { NoDataMessage } from "@/layouts/Admin";
import Search from "@Admin/components/Search";
import AddContent from "./addContent";
import EditContent from "./editContent";

const ListContents = () => {

  const user = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  let [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editData, setEditData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const  {data: {response: contents}} = await contentsAPI.getContents();
        setData(contents.filter(d => d.folderInfo !== null));
      } catch (error) {
        console.log(error);
      }
    }

    getData();
  },[]);

  const handleShowModal = () => setShowModal(true);

  const handleShowEditModal = contentID => {
    const editData = data.filter(d => d.id === contentID);
    setShowEditModal(true);
    setEditData(editData);
  };

  const handleCloseModal = info => {
    if(info !== undefined){
      setShowModal(false); 
      setData([...data, info]);
    }else{
      setShowModal(false);
    }
  }

  const handleCloseEditModal = info => {
    if (info !== undefined) {
      const info2 = data.map(d => d.id === info.id ? info : d);

      setShowEditModal(false);
      setData([...info2]);
    } else {
      setShowEditModal(false);
    }
  };

  const removeContent = async id => {
    try {
      // Get content info
      const content = data.find(d => d.id === id);
      const action_type = 'DELETE';
      const description = action_type + ' CONTENT: ' + content.title;
      const module = 'CONTENTS';

      const logInfo = {
        action_type,
        description,
        module,
        user: user.id
      };
      await contentsAPI.deleteContent(id);

      // Create log
      await logsAPI.createLog(logInfo);

      toast(<MsgNotification msg='Content has been deleted!' />);
      const info = data.filter(d => d.id !== id);
      setData(info);
    } catch (error) {
      toast.error("Content not deleted!");
    }
  };

  data = isSearching? filteredData : data;
  
  return (
    <>
    <Col lg={12}>
      <Row>
        <Col lg={6}>
          <Button variant="success" style={{ marginTop: "23px" }} onClick={handleShowModal} >
            <i className="pe-7s-plus"></i> Add new content
          </Button>
        </Col>
        <Col lg={6} className="pull-right">
          <Search data={data} attribute="title" text="Type a content title..." filteredData={(filteredData, isSearching) => {setFilteredData(filteredData); setIsSearching(isSearching);}} />
        </Col>
      </Row>
      {data.length > 0 ? 
        <Table striped bordered responsive hover style={{ marginTop: "23px" }}>
          <thead>
            <tr>
              <th>Title</th>
              <th>Box folder</th>
              <th>Roles</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.filter(d => d.folderInfo !== null).map(d => (
              <tr key={d.id}>
                <td>{d.title}</td>
                <td>{d.folderInfo.name}</td>
                <td>
                {d.roles.map((role,k) => (
                  <p key={k}>{role.name}</p>
                ))}
                </td>
                <td>
                  <Button variant="warning" onClick={() => handleShowEditModal(d.id)}>
                    <i className="pe-7s-pen"></i> Edit
                  </Button>
                  
                  <Button variant="danger"
                    onClick={() => {
                      const r = window.confirm(
                        "Are you sure you want to delete this item?"
                      );
                      if (r) removeContent(d.id);
                    }}>
                    <i className="pe-7s-trash"></i> Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        : 
        isSearching ? (
        <NoDataMessage>
          No results found.
          <br />
          Try adjusting your search to find what you're looking
          for.
        </NoDataMessage>
      ) : (
        <NoDataMessage>
          It looks like you don't have any information yet.{" "}
          <br />
          Once you add information, this page will show it.{" "}
        </NoDataMessage>
      )}

      {/*<div className="text-center">
        <Pagination>
          <Pagination.First />
          <Pagination.Prev />
          <Pagination.Item>{1}</Pagination.Item>
          <Pagination.Ellipsis />

          <Pagination.Item>{10}</Pagination.Item>
          <Pagination.Item>{11}</Pagination.Item>
          <Pagination.Item active>{12}</Pagination.Item>
          <Pagination.Item>{13}</Pagination.Item>
          <Pagination.Item disabled>{14}</Pagination.Item>

          <Pagination.Ellipsis />
          <Pagination.Item>{20}</Pagination.Item>
          <Pagination.Next />
          <Pagination.Last />
        </Pagination>
      </div>*/}
    </Col>

    <AddContent showModal={showModal} closeModal={handleCloseModal} />

    {showEditModal && (
      <EditContent
        data={editData[0]}
        allData={data}
        showModal={showEditModal}
        closeModal={handleCloseEditModal}
      />
    )}
  </>
  )
}

export default ListContents;