import { useEffect, useState } from 'react';
import { sessionsAPI } from '@/libs/api';
import { setCookie, logout } from '@/helpers/SessionFunctions';
import { getDataFromURL } from '@/helpers/RouterFunctions';
import Loading from '@/components/Loading';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {
  const [idToken, setIdToken] = useState(null);
  const { user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    //Function to get id token form auth0
    async function loadIdToken() {
      if (isAuthenticated) {
        const token = await getIdTokenClaims();
        setIdToken(token);
      }
    }
    loadIdToken();

    const getData = async () => {
      console.log('Ejecuta get data');
      //Send code and platform to API.
      const { code } = getDataFromURL();
      //Set data object to send api
      let data = {
        code,
        user,
        id_token: idToken?.__raw
      };

      //Load data from user.
      const result = await sessionsAPI.login(data);

      const { token } = result.data.response;
      delete result.data['token'];
      const userData = result.data.response;

      setCookie('token', token);
      setCookie('wp_user_a0Id', userData.uuid);
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('ID_TOKEN', idToken?.__raw);

      // Check if NodeBB cookie exists
      /* const nodebb_cookie = getCookie(process.env.REACT_APP_FORUMS_COOKIE);
      
      if(nodebb_cookie === '') {
        const user_email = result.data['email'];
        const user_nodebb_id = result.data['nodebb_id'];
        const user_nodebb_user = result.data['nodebb_user'];

        const jwt = require('jsonwebtoken');
        const nodebb_token = jwt.sign({id: user_nodebb_id, username: user_nodebb_user, email: user_email}, process.env.REACT_APP_FORUMS_JWT_SECRET);

        setCookie(process.env.REACT_APP_FORUMS_COOKIE,nodebb_token,process.env.REACT_APP_FORUMS_COOKIE_DOMAIN)
      } */
    };

    if (!isLoading && isAuthenticated && idToken != null) {
      getData()
        .then(() => {
          if (!isLoading && isAuthenticated && idToken != null) {
            window.location = `${process.env.REACT_APP_REDIRECT}dashboard`;
          }
        })
        .catch((error) => {
          alert(
            `You don't have access to the system. Please, contact with your Administrator System. ${error.message}`
          );
          logout(true);
        });
    }
  }, [user, isAuthenticated, isLoading, idToken]);

  return <Loading />;
};

export default Login;
