import { useAuth0 } from "@auth0/auth0-react";

const Logout = () => {
  const { logout } = useAuth0();
  localStorage.clear();

  return logout({ logoutParams: { returnTo: process.env.REACT_APP_REDIRECT } });
};

export default Logout;
