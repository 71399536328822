import http from "@/config/http";

/*************** <FILES> *********************/
export const getFileData = async (id) => http.get(`v1/files/${id}`);

export const getFileDownloadLink = async (id) => http.get(`v1/files/${id}/download`);

export const getFileEmbedUrl = async (id) => http.get(`v1/files/${id}/embed`);

export const searchFiles = async (query, boxFolders) => http.get(`v1/files/${query}/search/${boxFolders}`);

/*************** <FILES/> *********************/
