import { Container, Navbar } from "react-bootstrap";
import AdminNavbarLinks from "./AdminNavbarLinks";

const AdminNavbar = () => {
  
  const mobileSidebarToggle = (e) => {
    
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function() {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  }

  return (
    <Navbar>
      <Container fluid>
        <Navbar.Brand>
          {""}
        </Navbar.Brand>
        <Navbar.Toggle onClick={mobileSidebarToggle} />
      </Container>
      <Navbar.Collapse>
        <AdminNavbarLinks />
      </Navbar.Collapse>
    </Navbar>
  );
}

export default AdminNavbar;
