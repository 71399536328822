import { useRef, useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import socket from "@/config/sockets";
import { MsgNotification } from "@/config/toast";
import { notificationsAPI, contentsAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import { withRouter } from "@/helpers/RouterFunctions";
import { StyledHeader, SystemLogo } from "@/styles/Template";
import LogoTergar from "@/assets/img/var/logo-horizontal.png";
import Notification from "./Notification";
import ProfileBox from "./ProfileBox";
import SearchFiles from "./SearchFiles";
import MenuMobile from "./MenuMobile";
import {logout} from "@/helpers/SessionFunctions";

const Header = () =>  {
  const searchRef = useRef();
  const menuMobileRef = useRef();
  const user = useContext(UserContext);
  const [isSearching, setSearching] = useState(false);
  const [isActiveToggle, setActiveToggle] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [dataFolders, setDataFolders] = useState([]);

  const handleClickOutside = event => {
    if(searchRef.current && !searchRef.current.contains(event.target)){
      setSearching(false);
      //searchedFiles: [], 
      //valueSearched: ''
    }

    if(menuMobileRef.current && !menuMobileRef.current.contains(event.target)){
      setActiveToggle(false);
      //searchedFiles: [], 
      //valueSearched: ''
    }
  };

  const updateOnlineStatus = () => {
    if(navigator.onLine){
      toast(<MsgNotification msg="You are now online, reloading page in 10 seconds!" />);
      setTimeout(() => {  window.location.reload(); }, 10000);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("online",  updateOnlineStatus);

    const getData = async () => {
      try {
        let {data: {response: notifications}} = await notificationsAPI.getNotifications(user.id);
        const {data: {response: dataFolder}} = await contentsAPI.getContentsByRole(user.role.id);
  
        notifications = notifications.filter(d => !d.seen);
        setNotifications(notifications);
        setDataFolders(dataFolder.filter(d => d.folderInfo !== null));
      } catch (error) {
        console.error(error);
      }
    }

    getData();

    socket().socket.on('contents', function(data) {
      switch(data.type){
        case 'create':
          const newData = {...data.info};
          delete newData['roles'];
          setDataFolders(prevState => [...prevState, newData]);
        break;
        case 'update':
          setDataFolders( prevState => {
            // Verify if this content already exists on dataFolders state
            const contentExists = prevState.some(content => content.id === data.info.id);
            if (contentExists) {
              // Update content data
              return prevState.filter(content => content.id === data.info.id).push(data.info); 
            } else {
              return [...prevState, data.info];
            }
          });
        break;
        case 'destroy':
          setDataFolders(prevState => [...prevState.filter(d => d.id !== data.info.id)]);
        break;
        default: 
        break;
      }
    });

    socket().socket.on('notifications', function(data) {
      switch (data.type) {
        case "create":
          // If this notification is for the logged in user then add this notification to notifications state 
          if (data.info.user === user.id) {
            toast(<MsgNotification msg="You received a new notification!" />);
            setNotifications(prevState => [...prevState, data.info]);
          }
        break;

        case "destroy":
          // If this notification is a content notification
          if (data.info.role) {
            //If this notification is for the role of the logged in user then remove this notification from notifications state
            if (data.info.role === user.role.id) {
              setNotifications(prevState => prevState.filter(d => d.notification.id !== data.info.id));
            }
          }
          else {
            // If this notification is a newsletter notification
            setNotifications(prevState => prevState.filter(d => d.notification.id !== data.info.id));
          }
        break;

        default:
        break;
      }
    });

    socket().socket.on('roles', function(data) {
      switch (data.type) {
        case 'update':
          if(data.info.id === user.id) {
            if(navigator.onLine) {
              toast(<MsgNotification msg="Your role was changed, logging out in 10 seconds!" />);
              setTimeout(() => logout(true), 10000);
            }
          }
        break;
        default:
        break;
      }
    });
  },[user]);

  return (
    <StyledHeader isSearching={isSearching}>
      <nav className="navbar navbar-default fixed-top navbar-expand-lg">
        <div className="container-fluid">
          <div className="d-flex justify-content-start align-items-center d-none d-md-flex">
            <div className="navbar-brand">
              <SystemLogo src={LogoTergar} alt="Tergar" />
            </div>
          </div>
          
          {/* Brand and toggle get grouped for better mobile display */}
          <div className={"d-flex justify-content-start align-items-center " + (isSearching ? 'd-none' : 'd-flex d-md-none') + (isSearching ? ' logo-off' : ' logo-on')}>
            <button type="button" className="navbar-toggler collapsed d-block d-md-none" data-bs-toggle="collapse" data-bs-target="#navbarMobile" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setActiveToggle(true)}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-brand">
              <SystemLogo src={LogoTergar} alt="Tergar" />
            </div>
          </div>
            
          <div className="d-flex justify-content-end" style={{width: isSearching? '100%' : ''}}>
            <ul className="nav navbar-nav search-input">
              <SearchFiles
                ref={searchRef}
                isSearching={isSearching} 
                setSearching={(value) => setSearching(value)} 
                dataFolders={dataFolders}
              />
            </ul>
            <ul className="nav navbar-nav navbar-right flex-row align-items-center">
              <Notification notifications={notifications} setNotifications={(data) => setNotifications(data)} />
              <ProfileBox user={user} />
            </ul>
          </div>
        </div>

        {/***** Mobile Toggle Menu */}
        <MenuMobile ref={menuMobileRef} isActiveToggle={isActiveToggle} setActiveToggle={setActiveToggle} dataFolders={dataFolders} />
      </nav>
    </StyledHeader>
  );
}

export default withRouter(Header);
