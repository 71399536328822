import { Container, Row, Col } from "react-bootstrap";
import Card from "@Admin/components/Card";
import ListContents from './listContents';

const Contents = () =>
  <div className="content">
    <Container fluid>
      <Row>
        <Col md={12}>
          <Card title="Contents" ctTableFullWidth ctTableResponsive>
            <ListContents />
          </Card>
        </Col>
      </Row>
    </Container>
  </div>

export default Contents;
