import { stepsTourDashboard } from "@/config/tour";
import { usersAPI } from "../libs/api";

export const openTutorial = (user, setIsOpen, setSteps, setCurrentStep, setMessageOpen = null ) => {
  setSteps(stepsTourDashboard(() => closeTutorial(user, setIsOpen)));
  setCurrentStep(0);
  if(setMessageOpen)
    setMessageOpen(false);
  setIsOpen(true);
}

export const closeTutorial = async (user, setIsOpen, setMessageOpen = null) => {
  try {
    document.getElementsByTagName("html")[0].style.overflow = 'initial';
    document.body.style.overflow = 'initial';

    if(!user.welcomeTourDone){
      const userData = await usersAPI.updateTutorial(user.id);
      localStorage.setItem("user", JSON.stringify(userData.data.response));
    }
  } catch (error) {
    console.log(error);
  }

  if(setMessageOpen)
    setMessageOpen(false);
  setIsOpen(false);
}