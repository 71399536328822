import http from "@/config/http";

/*************** <ROLES> *********************/
export const getRoles = async () => http.get(`v1/roles`);

export const createRole = async (data) => http.post(`v1/roles`,data);
  
export const updateRole = async (id, role) => http.put(`v1/roles/${id}`, role);

export const deleteRole = async (id) => http.delete(`v1/roles/${id}`);

  /*************** <ROLES/> *********************/