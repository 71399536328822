import { TourText, ClearFix, BtnGold } from "../styles/Template";

export const stepsTourDashboard = (closeTour) => {
  return(
  [{
      selector: '[data-tut="reactour__role"]',
      content: () =>
        <div>
          <TourText>Type of Role</TourText>
          Here is the volunteer role you are serving in.
        </div>
    },
    {
      selector: '[data-tut="reactour__content"]',
      content: () =>
        <div>
          <TourText>Content</TourText>
          Here are your folders.
        </div>
    },
    {
      selector: '[data-tut="reactour__notificationsbell"]',
      content: () =>
        <div>
          <TourText>Notifications</TourText>
          Here you will see your notifications.
        </div>
    },
    {
      selector: '[data-tut="reactour__profile"]',
      content: () =>
        <div>
          <TourText>Profile</TourText>
          Here you can see your log-in information.
        </div>
    },
    {
      selector: '[data-tut="reactour__highlights"]',
      content: () =>
        <div>
          <TourText>Highlights</TourText>
          Your most frequently viewed files appears here.
        </div>
    },
    {
      selector: '[data-tut="reactour__notifications"]',
      content: () =>
        <div>
          <TourText>Notifications</TourText>
          Here appears your latest views and downloads.
          <ClearFix />
          <br />
          <BtnGold onClick={closeTour}>Got it!</BtnGold>
          <ClearFix />
        </div>
    }
  ])
};