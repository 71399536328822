import { useContext, useState } from "react";
import { Row, Col, Form, Button, Modal, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import { MsgNotification } from "@/config/toast";
import { rolesAPI, logsAPI } from "@/libs/api";
import { UserContext } from "@/libs/context/User";
import { validString } from "@/helpers/MainFunctions";
import { SpinnerLoading } from "@/styles/Template";

const EditRole = (props) => {

  const user = useContext(UserContext);
  let [name, setName] = useState("");
  let [roleOrder, setRoleOrder] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isChangeRoleName, setIsChangeRoleName] = useState(false);
  const [isChangeOrder, setIsChangeOrder] = useState(false);
  const [hasSentData, setHasSentData] = useState(false);

  const onChangeRoleName = e => {
    const name = e.target.value;
    //Validating role name.
    const response = validRole(name);
    setName(name);
    setIsChangeRoleName(true);
    setError(response.error);
    setErrorMessage(response.errorMessage);
  };

  const onChangeOrder = e => {
    const roleOrder = parseInt(e.target.value);
    setRoleOrder(roleOrder);
    setIsChangeOrder(true);
  };

  const onBlurRoleName = e => {
    const name = e.target.value.trim();
    const isChangeRoleName = name.trim() !== "";

    //Validating role name.
    const response = validRole(name);
    setName(name);
    setIsChangeRoleName(isChangeRoleName);
    setError(response.error);
    setErrorMessage(response.errorMessage);
  };

  // Send content information to API.
  const onSubmitRole = async () => {
    try {
      setHasSentData(true);
      const name2 = isChangeRoleName ? name : props.data.name;
      const roleOrder2 = isChangeOrder ? roleOrder : props.data.roleOrder;
  
      const info = {
        role: {
          name: name2,
          roleOrder: roleOrder2
        }
      };
  
      const action_type = 'UPDATE';
      const description = action_type + ' ROLE: ' + info.role.name;
      const module = 'ROLES';
  
      const logInfo = {
        action_type,
        description,
        module,
        user: user.id
      };

      const {data: {response: role} } = await rolesAPI.updateRole(props.data.id, info);

      // Create log
      await logsAPI.createLog(logInfo);

      toast(<MsgNotification msg="The role has been updated!" />);

      resetState();
      setHasSentData(false);
      props.closeModal(role);
    } catch (error) {
      setHasSentData(false);
      setError(true);
      setErrorMessage('Sorry! An error ocurred while processing your request.');
    }
  };

  //Search If a role exists.
  const searchRole = role => props.allData.filter(d => d.name === role).length > 0;

  const validRole = role => {
    const isDuplicated = searchRole(role);
    const isValidRole = validString(role);

    if (isDuplicated)
      return { error: isDuplicated, errorMessage: "Role name is duplicated." };

    if (isValidRole)
      return { error: isValidRole, errorMessage: "Role name is invalid." };

    return { error: false, errorMessage: "" };
  };

  const onHideModal = () => {
    resetState();
    props.closeModal();
  };

  const resetState = () => {
    setName("");
    setRoleOrder("");
    setError(false);
    setErrorMessage("");
  }

  const disabledButton = () => (!isChangeRoleName && !isChangeOrder) || error;

  const disabled = disabledButton();

  name = isChangeRoleName? name : props.data.name;
  roleOrder = isChangeOrder ? roleOrder : props.data.roleOrder;

  return (
    <>
      <Modal show={props.showModal} onHide={onHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {error && (
              <Col lg={12}>
                <Alert variant="danger" style={{position: "relative"}} onClose={() =>  { setError(false); setErrorMessage("");}} dismissible>
                  {errorMessage}
                </Alert>
              </Col>
            )}
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Role name</Form.Label>
                <Form.Control type="text" value={name} placeholder="Type the role name" onChange={onChangeRoleName} onBlur={onBlurRoleName}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Hierarchy</Form.Label>
                <Form.Select placeholder="select" onChange={onChangeOrder} value={roleOrder}>
                  <option value="0">Select a role order</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Form.Select>
              </Form.Group>
            </Col>

            <hr />

            <Col lg={12}>
              <Button className="pull-right" variant="success" onClick={onSubmitRole} disabled={disabled} >
                {hasSentData ? <SpinnerLoading /> :  "Save"}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditRole;