import { Element } from "react-scroll";
import { BannerLanding } from "@/layouts/Landing";
import ScreenSystem from "@/assets/img/var/screen-dash.png";
import ScreenSystem2 from "@/assets/img/var/screen-dash2.png";

const Home = () => 
<Element name="home">
  <div className="container-fluid">
    <div className="row">
      <BannerLanding>
        <div className="offset-0 col-12 offset-sm-1 col-sm-10 offset-lg-2 col-lg-8">
          <h1>Community Leadership &amp; Training</h1>
          <p>
            Welcome! This community portal contains information and resources needed to manage your Tergar Practice Group and Tergar Meditation Community.
          </p>
        </div>
        <img src={ScreenSystem} alt="Community Leadership" className="img-fluid center-block d-none d-md-block"/>
        <img src={ScreenSystem2} alt="Community Leadership" className="img-fluid center-block d-block d-md-none"/>
      </BannerLanding>
    </div>
  </div>
</Element>

export default Home;