import { useEffect, useState } from "react";
import socket from "@/config/sockets";
import { newslettersAPI } from "@/libs/api";
import { dateFormat } from "@/helpers/TimeFunctions";
import { NewsletterWrapper } from "@/layouts/Landing";
import { BtnGold, TitleH2 } from "@/styles/Template";
import NewsletterImgBk  from "@/assets/img/var/newsletter.png";
import Loading from '@/components/Loading';

const Newsletters = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const {data: {response: newsletters}} = await newslettersAPI.getNewsletters();
        setData(newsletters);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

    getData();

    socket().socket.on('newsletters', function (data) {
      switch (data.type) {
        case 'create':
          // Add this newsletter to newsletters state
          setData(prevState => [...prevState, data.info]);
        break;

        case 'destroy':
          // Remove this newsletter from newsletters state
          setData(prevState => prevState.filter(d => d.id !== data.info.id));
        break;

        default:
        break;
      }

    });
  },[]);

  return (
    <NewsletterWrapper>
      <div className="col-12 col-md-12">
        <TitleH2 className="mt-4">Newsletters</TitleH2>
      </div>
      {isLoading ?
        <Loading />
        :
        <table width="100%" cellPadding="0" className="border-outside-newsletter">
          <tbody>
            {data.length > 0 ?
              data.map((d,k) => (
                <tr key={d.id}>
                  <td className="info-newsletter">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <h4>{d.title}</h4>
                      </div>
                      <div className="col-12 col-md-6">
                        <h4 className="date-newsletter">
                          {dateFormat(d.newsletterDate, "DD / MMM / YYYY")}
                        </h4>
                      </div>
                    </div>
                    <div className="col-12">
                      <p className="description-newsletter">
                        <span dangerouslySetInnerHTML={{ __html: d.description }}/>
                      </p>
                      <BtnGold href={d.newsletterLink} target="_blank">Read more</BtnGold>
                      {data.length - 1 !== k && <hr></hr>}
                    </div>
                  </td>
                </tr>
              ))
              :
              <tr className="archive">
                <td className="info-clear-notification">
                  <p className="legend-notification">The newsletters are shown in here.</p>
                  <img src={NewsletterImgBk} className="img-responsive center-block" alt="Newsletters are shown in here"/>
                </td>
              </tr>
            }
          </tbody>
        </table>
      }
    </NewsletterWrapper>
  );
}

export default Newsletters;