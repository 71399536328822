import styled, { createGlobalStyle } from "styled-components";
import BkGold from "../assets/img/var/bk-tergargold.gif";
import AudioFile from "../assets/img/icon/file-audio.svg";
import PdfFile from "../assets/img/icon/file-pdf.svg";
import DocFile from "../assets/img/icon/file-doc.svg";
import XlsFile from "../assets/img/icon/file-xls.svg";
import PptFile from "../assets/img/icon/file-ppt.svg";
import ZipRarFile from "../assets/img/icon/file-zip.svg";
import UnkFile from "../assets/img/icon/file-unknow.svg";
import VidFile from "../assets/img/icon/file-video.svg";
import ImgFile from "../assets/img/icon/file-img.svg";
import WebLink from "../assets/img/icon/file-link.svg";
import FolderFile from "../assets/img/icon/folder.svg";
import ProfileImg from "../assets/img/var/user.svg";
import NotificationImg from "../assets/img/var/notification.svg";
import SearchImg from "../assets/img/var/search.svg";
import BkLastOpenend from "../assets/img/var/bk-line-circle.png";
import BkNotificaionDash from "../assets/img/var/bk-line-notification.gif";
import AvatarProfile from "../assets/img/var/profile-avatar.png";

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Barlow:400,500,600|Open+Sans:300,400,600&display=swap');
    html,body{font-weight: 400; color:#353535 !important; font-size: 14px; margin:0; height: 100%; position: relative;}
    .ml-btn{margin-left:10px;}

#app {
  height: 100%;
}

a {
  text-decoration: none;
}

textarea:focus, input:focus{
    outline: none;
}

::-webkit-scrollbar
    {
      width: 5px;  /* for vertical scrollbars */
      height: 5px; /* for horizontal scrollbars */
    }
    
    ::-webkit-scrollbar-track
    {
      background: rgba(0, 0, 0, 0.1);
    }
    
    ::-webkit-scrollbar-thumb
    {
      background: rgba(0, 0, 0, 0.5);
    }
.iframe-responsive {
    border: none;
    width: 100%;
    height: 100%;
    display: block;
    min-height: 400px;
}

.Toastify__progress-bar--default{
    background: #E39F24 !important;
}

.dropdown-menu > div > div > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333 !important;
}

.mt-70{
  @media (max-width: 767px) {
    margin-top: -60px;
  }
}

.forums{
  height: 100%;
  @media (max-width: 767px) {
    margin-top: -60px;
  }
}

.h100 {
  height: 100%;
}
`;

export const BtnGold = styled.a`
  display: inline-block !important;
  cursor: pointer;
  border: 1px solid #e39f24;
  border-radius: 4px;
  padding: 5px 8px !important;
  color: #e39f24 !important;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.5px;
  font-size: 12px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e39f24 !important;
    color: #fff !important;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.35);
    text-decoration: none !important;
  }
`;

export const BtnGold2 = styled.button`
  display: inline-block !important;
  border: 1px solid #e39f24;
  border-radius: 4px;
  padding: 5px 8px !important;
  color: #fff !important;
  background: #e39f24 !important;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.5px;
  font-size: 12px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e39f24 !important;
    color: #fff !important;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.35);
  }
`;

export const BtnDownload = styled.button`
  display: inline-block !important;
  border: none;
  padding: 5px 8px !important;
  color: #e39f24 !important;
  background: none;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.5px;
  font-size: 20px;
  transition: all 0.3s ease;

  i {
    font-weight: bold !important;
  }

  a,
  a:hover {
    color: inherit;
  }
`;

export const StyledHeader = styled.header`
  font-family: "Barlow", sans-serif;

  @media (min-width: 768px) {
    .navbar-header .navbar-nav .dropdown-menu {
      position: absolute;
      background-color: #fff;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    }

    .navbar-header > .navbar-right {
      margin-right: 16px;
    }

    .navbar-right .dropdown-menu {
      right: 0;
      left: auto;
    }

    #bs-example-navbar-collapse-1 {
      @media (max-width: 767px) {
        padding-right: 0;
      }
    }

    #bs-example-navbar-collapse-1 > .navbar-right > li {
      border-bottom: 1px solid #e5e5e5;
    }
  }

  .nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
  }

  .navbar-nav > li > a {
    color: #777;
    text-decoration: none;
  }

  .collapse {
    width: 100%;
  }
  
  .navbar {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color:  #e7e7e7;
    border-radius:0;
    margin:0;
    background-color:#fff;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .navbar-default .navbar-toggler {
    border-color: #ccc;
  }

  .navbar-default .navbar-nav > .disabled > a, 
  .navbar-default .navbar-nav > .disabled > a:focus, 
  .navbar-default .navbar-nav > .disabled > a:hover {
    color: #ccc;
    background-color: transparent;
  }

  .navbar-header {
    width: 100%;

    @media (max-width: 767px) {
      width: auto;
    }
  }

  .navbar-brand {
    height: 62px;
    padding: 12px 15px;

    @media (max-width: 991px) {
      height: 56px;
      padding: 8px 15px;
      max-width: 140px;
    }

    @media (max-width: 767px) {
      height: 48px;
      padding: 10px 0 8px 10px;
      max-width: 140px;
    }
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    margin-left: 4px;
  }

  .dropdown-toggle::after {
    display:none;
  }

  @media (max-width: 767px) {

    .search-effect{
      ${props => props.isSearching ? 'display:none' : 'display:block'};
      position: absolute;
    }

    #navbarMobile .dropdown-menu.show {
      border: none;
    }
  }

  .navbar-nav {
    margin-top: 14px;

    @media (max-width: 991px) {
      margin-top: 12px;
    }

    @media (max-width: 767px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .navbar-right {
    margin-right: 0;
  }

  .navbar-right .dropdown-menu {
    right: 0px;
    left: auto;
  }

  .navbar-toggler {
    margin-right: 2px;
    padding: 5px;
  }

  .navbar-toggler > .navbar-toggler-icon {
    width: 24px;
    height: 24px;
  }
  
  .navbar-default .navbar-toggler:focus, .navbar-default .navbar-toggler:hover {
    box-shadow: none;
    background-color: #ddd;
  }

  .info-clear-notification {
    padding: 6px 15px;
    text-align: center;
    font-weight: bold;
    color: #aaa;
    white-space: nowrap;
  }

  .notification-xs {
    position: relative;
    float: right;
    margin: 9px 2px 0 0;
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .notification-xs .dropdown-menu {
    right: 0;
    left: auto;
    min-width: 248px;
  }

  .notification-xs .dropdown-menu > div > div > li > a {
    white-space: normal;
  }

  .see-all-notification {
    text-align: center;
    background: #f7f7f7;
    text-shadow: 1px 1px #fff;
    padding: 6px;
    margin-bottom: -5px;
    margin-top: 10px;
    border-top: 1px solid #e5e5e5;
    font-weight: 500;
    border-radius: 0 0 5px 5px;
  }

  .see-all-notification a {
    font-weight: 500;
  }

  .profile-user-menu {
    background-size: 26px;
    padding: 6px;
    border: none !important;
    color: #353535 !important;

    @media (max-width: 767px) {
      padding: 4px 6px;
      background: url(${ProfileImg}) center no-repeat;
      width: 30px;
      height: 28px;
      background-size: 24px;
    }
  }

  .profile-display {
    padding: 6px 20px 16px 5px;
    width: 250px;
  }

  .profile-display > span {
    display: block;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 22px;
    overflow: hidden;
  }

  .profile-display span:first-child {
    font-size: 16px;
    font-weight: 500;
  }

  .profile-display span:nth-child(2) {
    color: #e39f24;
    font-weight: bold;
    font-size: 13px;
    height: 18px;
  }

  .profile-display span:nth-child(3) {
    text-overflow: ellipsis;
  }

  .profile-display span:nth-child(4) {
    margin-bottom: 16px;
  }

  .profile-display span > a {
    color: #337ab7;
    text-decoration: none;
    padding-left: 14px;
    padding-right: 14px;
  }

  .profile-display div > a {
    margin-right: 10px;
    text-decoration: none;
  }

  .notification-menu {
    position: relative;
    background: url(${NotificationImg}) center no-repeat;
    background-size: 26px;
    width: 34px;
    padding: 6px;
    margin: 0 15px;
    border: none !important;

    @media (max-width: 767px) {
      background-size: 22px;
      width: 30px;
      padding: 4px;
      margin: 0 6px;
    }
  }

  .notification-menu span {
    position: absolute;
    background: #b20f41;
    color: #fff;
    width: 14px;
    height: 14px;
    right: 2px;
    top: 2px;
    text-align: center;
    line-height: 1.25;
    font-size: 10px;
    font-weight: 600;
    border-radius: 20px;
    font-family: "Open Sans", sans-serif;

    @media (max-width: 767px) {
      font-size: 8px;
      width: 12px;
      height: 12px;
    }
  }

  .notification-dropdown .dropdown-menu{
    padding: 9px 0;
    max-height: 350px;
    overflow-y: scroll;

    @media  (max-width: 767px){
      min-width: 150px;
    }
  }

  .notification-dropdown .dropdown-menu > div > div > li > a{   
    @media  (max-width: 767px){
      white-space: normal;
      padding: 5px 14px;
    }
  }

  .search-input {
    @media (max-width: 767px) {
      width: ${props => !props.isSearching ? "70px" : "calc(100% - 85px)" };
      margin: 10px 0 0px;
      transition: all 600ms ease-in-out;
    }
  }

  .search-nav {
    position: relative;
    width: 250px;

    @media (max-width: 767px) {
      width: calc(100% - 15px);
      margin-bottom: 8px;
    }
  }

  .logo-off {
    width: 0;
    animation: off 600ms forwards;

    @keyframes off {
      0% {
        opacity: 0;
        overflow: hidden;
      }

      100% {
        opacity: 1;
        overflow: visible;
      }
    }
  }
  .logo-on {
    animation: on 6s forwards;

    @keyframes on {
      0% {
        width: 0;
      }

      100% {
        width: auto;
      }
    }
  }

  .search-nav input {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 40px 6px 12px;
    line-height: 1.42857143;
    color: #555;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 40px;

    @media (max-width: 767px) {
      background-color: ${props => !props.isSearching ? "inherit" : "#f9f9f9"};
      border: ${props => !props.isSearching ? "none" : "1px solid #ccc"};
      ${props => !props.isSearching ? "": "-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);"}
      ${props => !props.isSearching  ? "": "box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);"}
      transition: all 600ms ease-in-out;
    }
  }

  .search-nav input::placeholder {
    @media (max-width: 767px) {
      ${props => !props.isSearching  && "color: transparent;"}
      transition: all 600ms ease-in-out;
    }
  }

  .search-nav button {
    padding: 7px;
    position: absolute;
    width: 44px;
    border: none;
    top: 0;
    right: 0;
    background: url(${SearchImg}) center no-repeat;
    background-size: 21px;
  }

  .search-nav button:hover {
    opacity: 0.75;
  }

  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:focus,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .show > a,
  .navbar-default .navbar-nav > .show > a:focus,
  .navbar-default .navbar-nav > .show > a:hover {
    color: #555;
    background-color: #f9f9f9;
    border-left: 4px solid #e39f24;
  }

  .navbar-default .navbar-nav .show .dropdown-menu > div > div > li > a:hover,
  .navbar-default .navbar-nav .show .dropdown-menu > div > div > li > a:focus {
    text-decoration: underline;
  }

  .dropdown-toggle[aria-expanded="true"] {
    color: #555;
    background-color: #f9f9f9;
    border-left: 4px solid #e39f24;
  }

  .dropdown-item:focus, .dropdown-item:hover {
    background-color: transparent;
  }

  .dropdown-menu > li > a {
    color: #333;
    text-decoration: none;
  }
`;

export const SystemLogo = styled.img`
  height: 100%;
  width: auto;
`;

export const StyledSidebar = styled.div`
  background: #fff;
  position: fixed !important;
  border-right: 1px solid #e7e7e7;
  bottom: 0;
  top: 63px;
  overflow-y: scroll;
  padding: 0 !important;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;

  @media (max-width: 991px) {
    top: 56px;
  }

  .panel {
    background-color: transparent;
    box-shadow: none;
    margin-top: 4px;
    line-height: 1.25;
  }

  .list-group-item {
    background-color: transparent !important;
    border: none;
    padding: 12px 24px 12px 14px;
    position: relative;
  }

  .list-group-item[aria-expanded="true"]::before {
    content: " ";
    position: absolute;
    background: #e39f24;
    top: 0;
    bottom: 0;
    width: 3px;
    left: 0;
  }

  .list-group-item[aria-expanded="true"] {
    border-bottom: none !important;
  }

  a.list-group-item,
  button.list-group-item {
    color: #353535;
    text-shadow: 1px 1px 0px #fff;
    transition: all 0.3s ease;
    border-bottom: 1px solid #eee;
  }

  .list-group-item .bi-chevron-down {
    position: absolute;
    right: 10px;
    top: 15px;
    font-size: 13px;
    color: #e39f24;
  }

  .collapse.show {
    border-bottom: 1px solid #e9e9e9;
  }

  .collapsed .bi-chevron-down::before {
    color: #353535;
  }

  collapse.show a {
    color: #353535;
  }

  a.list-group-item:focus,
  a.list-group-item:hover {
    background: #f9f9f9 !important;
    color: #353535;
  }

  .collapse a,
  .collapsing a {
    padding: 8px 15px 8px 25px;
    display: block;
    color: #353535;
    font-weight: 400;
    background-position: 16px 9px;
    background-size: 0;
    transition: background 0.3s ease;
    font-family: "Barlow", sans-serif;
  }

  .collapse a:last-child,
  .collapsing a:last-child {
    margin-bottom: 12px;
  }

  .collapse a:hover,
  .collapsing a:hover {
    font-weight: 500;
    background: url(${BkGold}) 16.5px 12px no-repeat;
    background-size: 4px;
  }

  .list-group-item.active,
  .list-group-item.active:focus,
  .list-group-item.active:hover {
    border-color: #eee;
  }

  .list-group-item.active::before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    background: #e39f24;
  }

  .link-disabled {
    color: rgba(114, 112, 112, 0.5) !important;
    cursor: default;

    &:hover,
    &:focus {
      background: transparent !important;
    }
  }
`;

export const TitleMenu = styled.span`
  color: #e39f24;
  text-shadow: 1px 1px 0px #fff;
  text-transform: uppercase;
  padding: 18px 15px 4px;
  font-weight: 600;
  display: block;
`;

export const ClearFix = styled.span`
  clear: both;
  display: block;
`;

export const TitleH1 = styled.h1`{
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size:36px;

    @media  (max-width: 767px){
      font-size: 30px;
    }
}
`;

export const TitleH2 = styled.h2`{
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size:26px;
  
  span {
    font-size: 16px;
    cursor: pointer;
  }

  @media  (max-width: 767px){
    font-size: 22px;
    padding: 8px 2px;
    border-bottom: 1px solid #ccc;
    margin-bottom:0;
  }

  .bi{
    font-size: 12px;
    opacity: .75;
  }
}
`;

export const ContentWrapper = styled.div`
  .mt-initial-table {
    margin-top: 40px;
  }

  .wrapp-main {
    height: 100%;
    padding: 62px 20px 0;
    font-family: "Barlow", sans-serif;

    @media (max-width: 767px) {
      padding: 62px 8px 0;
    }
  }

  table {
    margin-bottom: 40px;
  }

  .box-border-gray {
    border: 1px solid #e7e7e7;
  }

  .border-outside {
    border-top: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;

    @media (max-width: 767px) {
      border-color: transparent;
    }
  }

  .border-all {
    border: 1px solid #e7e7e7;

    @media (max-width: 767px) {
      border: none;

      p {
        text-align: center;
      }
    }
  }

  height: 100%;

  .line-row:hover {
    background: #fafafa;
  }

  .info-date-file {
    width: 22%;
    background: url(${BkLastOpenend}) right center no-repeat;
    padding: 20px 46px 20px 15px;
    text-align: right;

    @media (min-width: 1600px) {
      width: 15%;
    }

    @media (max-width: 991px) {
      width: 30%;
    }
  }

  .description-file {
    width: 78%;
    background-position: 16px center;
    background-repeat: no-repeat;
    background-size: 30px;
    padding: 20px 15px 20px 60px;

    @media (min-width: 1600px) {
      width: 85%;
    }

    @media (max-width: 991px) {
      width: 70%;
    }

    @media (max-width: 767px) {
      background-size: 20px;
      padding: 20px 15px 20px 44px;
    }
  }

  .info-date-notification {
    width: 22%;
    background: url(${BkNotificaionDash}) right center no-repeat;
    padding: 10px 46px 10px 15px;
    text-align: right;

    @media (min-width: 1600px) {
      width: 15%;
    }

    @media (max-width: 991px) {
      width: 30%;
    }
  }

  .description-notification {
    width: 78%;
    padding: 10px 15px;

    @media (min-width: 1600px) {
      width: 85%;
    }

    @media (max-width: 991px) {
      width: 70%;
    }
  }

  .info-clear-notification {
    width: 100%;
    padding: 20px;
    text-align: center;
    font-weight: bold;
    color: #aaa;

    p.legend-notification {
      margin-top: 10px;
    }
  }

  .no-border-xs {
    @media (max-width: 767px) {
      border-color: transparent !important;
    }
  }

  .profile-dashboard {
    padding: 20px 15px 20px 86px;
    background: url(${AvatarProfile}) 12px 16px no-repeat;
    background-size: 64px;
  }

  .profile-dashboard:hover {
    background-color: #fff;
  }

  .profile-dashboard span {
    display: block;
    line-height: 1.25;
  }

  .profile-dashboard span:first-child {
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    height: 24px;
    overflow: hidden;
  }

  .profile-dashboard span:nth-child(2) {
    color: #5b6cd0;
  }

  .profile-dashboard span:nth-child(3) {
    font-weight: bold;
    opacity: 0.75;
    font-size: 13px;
    line-height: 1.15;
  }

  .mt26 {
    margin-top: 26px;
  }

  table a {
    color: #353535;
    background-repeat: no-repeat;
    display: block;
  }

  table th {
    border-bottom: 2px solid #dedede;
    font-weight: 500;
    padding-bottom: 2px;
  }

  tbody tr td {
    padding: 3px;
  }

  .table-detail-file a {
    padding: 8px 10px 8px 28px;
    background-size: 18px;
    background-position: 1px center;
  }

  .archive {
    border-bottom: 1px solid #e7e7e7;
  }

  .archive:hover {
    background: #fafafa;
  }

  .audiofile {
    background-image: url(${AudioFile});
  }

  .pdffile {
    background-image: url(${PdfFile});
  }

  .docfile {
    background-image: url(${DocFile});
  }

  .imgfile {
    background-image: url(${ImgFile});
  }

  .weblink {
    background-image: url(${WebLink});
  }

  .ziprarfile {
    background-image: url(${ZipRarFile});
  }

  .pttfile {
    background-image: url(${PptFile});
  }

  .xlsfile {
    background-image: url(${XlsFile});
  }

  .unkfile {
    background-image: url(${UnkFile});
  }

  .vidfile {
    background-image: url(${VidFile});
  }

  .folderfile {
    background-image: url(${FolderFile});
  }

  .border-outside-newsletter {
    border: 1px solid #e7e7e7;

    @media (max-width: 767px){
      border-color: transparent;
    }
  }

  .info-newsletter {
    padding: 10px 20px 10px 20px;

    @media (max-width: 767px){
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }

    hr {
      margin-bottom: 0;
    }
  }

  .info-newsletter h4 {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
  }

  .info-newsletter a {
    float: right;
    margin-top: -45px;

    @media (max-width: 767px){
      float: right;
      margin-top: -25px;
    }
  }

  .date-newsletter {
    text-align: right;

    @media (max-width: 767px){
      text-align: left;
      font-size: 14px !important;
      font-weight: 300 !important;
      font-family: 'Barlow',sans-serif !important;
      margin-top: 0; 
    }
  }

  .description-newsletter {
    padding-bottom: 26px;

    @media (max-width: 767px){
      height: 0;
      overflow: hidden;
      padding: 0;
      margin: 30px 0 0;
    }
  }

  .border-outside-newsletter tr:last-child .info-newsletter .description-newsletter {
    border: none;
  }

  .borde-wht {
    border: 1px solid #fff;
  }
`;

export const BtnPointEllipsis = styled.a`
  padding: 4px 10px;
  display: inline-block;

  &:hover {
    background-color: #f8f8f8;
  }
`;

export const MsgWelcome = styled.table`
  img {
    width: 70px;
    height: 70px;
    margin-bottom: -10px;
  }

  td {
    padding: 30px 30px 44px !important;

    @media (max-width: 767px) {
      padding: 0px 0px 14px !important;
    }
  }

  h1 {
    margin-bottom: 26px;
  }
`;

export const LearnMoreTxt = styled.p`
  padding: 6px 10px;
`;

export const LoadImg = styled.div`
  clear: both;
  padding: 50px 10px;
  text-align: center;

  img {
    display: block;
    margin: 30px auto;
    max-width: 100%;
  }
`;

export const TourText = styled.h4`
  color: #5c6bc0;
  margin: 10px 0 10px;
  display: block;
  font-weight: 400;
`;

export const BackBlack = styled.section`
  display: block;
  background: rgba(0, 0, 0, 0.75) !important;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;

  @media (max-width: 767px) {
  }
`;

export const CenterHorizontal = styled.div`
  margin: 0 auto;
  height: 100%;
  display: table;
  width: 620px;

  @media (max-width: 767px) {
    width: 98%;
  }
`;

export const CenterVertical = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

export const BoxMessage = styled.div`
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.17);
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  border-radius: 4px;
  position: relative;
  padding: 14px 14px 18px;
  text-align: right;

  img {
    width: 100%;
    margin-bottom: 20px;
  }

  a {
    margin: 2px 6px;
    color: #5b6dc0;
    text-decoration: none;
  }
`;

export const LegendBanner = styled.div`
  position: absolute;
  top: 6px;
  text-align: center;
  left: 25px;
  right: 25px;
  font-family: "Open Sans", sans-serif;

  @media (max-width: 767px) {
    top: 16px;
    padding: 0 24px;
  }

  h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  a {
    display: inline-block;
    background: #5b6dc0;
    color: #fff;
    padding: 6px 15px;
    border-radius: 4px;
    transition: all 0.3s ease;

    &:hover {
      text-decoration: none;
      border: none;
      opacity: 0.85;
    }
  }
`;

export const BreadCrumbs = styled.div`
  //background: #f7f7f7;
  text-shadow: 1px 1px 0 #fff;
  color: #aaa;
  margin-top: 24px;
  padding: 8px 2px;

  .bi {
    font-size: 10px;
    margin: 0 4px;
    color: #456ccd;
  }

  a {
    color: #353535;
  }
`;

export const DocumentVisualizer = styled.section`
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  padding-top: 30px;

  @media (max-width: 767px) {
    padding-top: 40px;
    height: calc(100vh - 110px);
  }

  iframe {
    border-left: 1px solid rgb(204, 204, 204);
    border-right: 1px solid rgb(204, 204, 204);
    border-bottom: 1px solid rgb(204, 204, 204);
    border-top: none;
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .bp-default-logo {
    opacity: 0.1 !important;
  }
`;

export const BtnDocumentVisualizer = styled.div`
  position: absolute;
  width: 100%;
  background-color: #fcfcfc;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
  height: 48px;
  z-index: 1;
`;

export const BtnReturnFolder = styled.div`
  a {
    display: block;
    color: #666;
    font-weight: 600;
    float: left;
    padding: 5px 10px;
    border-radius: 3px;
    margin: 10px 16px 0;
    transition: all 0.3s ease;
    background-color: rgba(0, 0, 0, 0.05);

    &:hover {
      color: #e39f24;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.02);
    }
  }
`;

export const BtnDownloadFile = styled.a`
  display: block;
  color: #666;
  font-weight: 600;
  float: right;
  padding: 5px 10px;
  border-radius: 3px;
  margin: 10px 16px 0;
  transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;

  &:hover {
    color: #e39f24;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

export const SearchResult = styled.div`
  background:#fff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  position:absolute;
  top: 34px;
  right:0;
  overflow-y: scroll;
  width: auto;
  max-width: 300px;
  z-index: 2;
  max-height: 350px;

  @media  (max-width: 767px){
    max-width: 100%;
    width: 100%;
  }

  a{
    color:#353535;
    padding: 8px 16px;
    display: block;
    border-bottom: 1px solid #d8d8d8;
    white-space: nowrap;
    &:hover{
        background: #f9f9f9;
        color:#353535;
    }
    @media  (max-width: 767px){
      white-space: normal;
    }
  }

  a:last-child{
    border:none;
  }
  
  .no-result-search{
    white-space: nowrap;
    display:block;
    padding: 8px 16px;
    text-align: center;
    font-weight: 600;
  }
`;

export const ControlContactSection = styled.div`
  position: fixed;
  top: 60px;
  left: 16.66666667%;
  padding: 4px 20px 0;
  width: 83.33333333%;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 1;

  @media  (max-width: 1199px){
    width: 83.33333333%;
  }

  @media  (max-width: 991px){
    left: 25%;
    width: 75%;
  }

  @media  (max-width: 767px){
    left: 0px;
    width: 100%;
    padding: 0 8px;
    top: 40px;

    h2{
      border-color:transparent;
    }
  }
`;

export const HeadTableContact = styled.table`
  width: 100%;
  font-weight: 500;
  border:none !important;
  border-bottom: 2px solid #e7e7e7 !important;
  margin: 10px 0 0 0 !important;

  td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4){
    padding: 0 20px 4px !important;
  }
`;

export const ContactNameEmailPosition = styled.td`
  width: 22%;
  padding: 10px 20px !important;
  border-bottom: 1px solid #e7e7e7; 
`;

export const ContactResponsibilities = styled.td`
  width: 34%;
  padding: 10px 20px !important;
  border-bottom: 1px solid #e7e7e7;
  
  span.description {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
  }
`;

export const BodyTableContact = styled.table`
  width: 100%;
  border: none !important; 
  margin-top: 112px;

  .name-contact{
    display: block;
    font-size: 16px; 
    color: #5c6bc0;
    font-weight: 500;
  }

  @media (max-width: 767px){
    margin-top: 55px;
  }
`;

export const DividerLetter = styled.td`
  border: none !important;
  background-color: #f7f7f7; 
  text-transform: uppercase;
  text-shadow: 1px 1px 0 #fff;
  padding: 10px 20px !important;
  font-weight: 500;
  font-size: 18px;
`;

export const SearchContact = styled.div`
  float: right;
  position: relative;
  margin-top: 18px;

  input{
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 40px 6px 12px;
    line-height: 1.42857143;
    color: #555;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 40px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  }

  button{
    position: absolute;
    width: 44px;
    border: none;
    top: 0;
    bottom: 0;
    right: 0;
    background: url(${SearchImg}) center no-repeat;
    background-size: 21px;
  }

  @media (max-width: 767px){
    width: 100%;
    margin-top: 4px; 
  }
`;

export const BtnAllForums = styled.a`
  color:#e39f24;
  text-transform: uppercase;
  letter-spacing: 1;
  display: inline-block;
  text-align: center;
  padding: 2px 5px; 
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  margin-top: 20px;

  &:hover {
    color: #e39f24;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

export const Quotes = styled.div`
  border: 1px solid #fafafa;
  padding: 20px 15px 20px;
  background-color: #fafafa;

  p {
    padding: 5px 30px 5px;
  }

  .name {
    font-size: 16px;
    text-align: right;
    margin-top: 20px;
    display: block;
  }

  @media (max-width: 767px){
    margin-top: -40px; 
  }
`;

export const OpenQuotesImg = styled.img`
  display: block;
  height: 20px;
  width: 25px;
`;

export const ClosedQuotesImg = styled.img`
  height: 20px;
  width: 25px;
  margin-top: -30px;
  float: right;
`;

export const SpinnerLoading = styled.div`
  border: 3px solid rgba(0, 0, 0, 0.1);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-left-color: #fff;

  animation: spin 1s ease infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`